import { Control } from 'react-hook-form';

import FormFields from 'components/UI/FormFields';
import { IFormField } from 'types/formFields';
import { InputTypes } from 'enums/input-types';
import useAuthStore from 'store/auth';
import { Profile } from 'types/profile';
import { useEffect } from 'react';
import { Alert, Stack } from '@mui/material';

interface Props {
	control: Control;
	errors: any;
	watch: any;
	setValue: any;
	register: any;
}

const NameFields: React.FC<Props> = ({ control, errors, watch, setValue, register }) => {
	const session: Profile = useAuthStore((state) => state.session);

	useEffect(() => {
		if (!session) return;
		setValue('first_name', session.FirstName);
		setValue('last_name', session.LastName);
		setValue('email', session.Email);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [session]);

	if (!session) return <>
		<Stack sx={{
			width: '100%',
			margin: 'auto',
			mt: 8,
			alignItems: 'center'
		}}>
			<Alert severity="error">
				This account is not activated. Please check your email for activation link.
			</Alert>
		</Stack>
	</>

	const fields: IFormField[] = [
		{
			name: 'first_name',
			placeholder: 'First name',
			type: InputTypes.TEXT,
			defaultValue: session.FirstName
		},
		{
			name: 'last_name',
			placeholder: 'Last Name',
			type: InputTypes.TEXT,
			defaultValue: session.LastName
		},
		{
			name: 'email',
			placeholder: 'Email',
			type: InputTypes.TEXT,
			defaultValue: session.Email,
			disabled: true
		},
		{
			name: 'NewPassword',
			label: 'New Password',
			type: InputTypes.PASSWORD,
			placeholder: "********",
			hidden: session.UserInvited !== 1
		},
		{
			name: 'ConfirmPassword',
			label: 'Confirm New Password',
			type: InputTypes.PASSWORD,
			placeholder: "********",
			hidden: session.UserInvited !== 1
		}
	];

	return (
		<>
			{fields.map((field: any) => (
				<FormFields {...field} key={field.name} control={control} errors={errors} watch={watch} setValue={setValue} register={register} />
			))}
		</>
	);
};

export default NameFields;
