import { Suspense } from 'react';
import { Box, Drawer } from '@mui/material';
import { TModal, emptyModal } from 'types/modal';
import useModalStore from 'store/modal';
import { Modals } from 'enums/modals';

const Modal: React.FC = () => {
	const modal: TModal = useModalStore((state) => state.modal);
	const setModal = useModalStore((state) => state.setModal);

	return (
		<Drawer
			sx={{ position: 'relative', zIndex: (theme) => theme.zIndex.drawer + 2 }}
			variant="persistent"
			anchor={modal.anchor}
			open={modal.type !== Modals.EMPTY}
			onClose={() => setModal(emptyModal)}
			PaperProps={{ sx: { bgcolor: 'rgba(0,0,0,.1)', width: 1, height: '100vh' } }}>
			<Suspense fallback={null}>
				<Box sx={{ position: 'relative', top: '50%', transform: 'translateY(-50%)' }}>{modal.content}</Box>
			</Suspense>
		</Drawer>
	);
};

export default Modal;
