import { Box, Typography } from '@mui/material';
import useAuthStore from 'store/auth';

const ResetInstructions: React.FC = () => {
	const email = useAuthStore((state) => state.email);

	return (
		<Box mb={6} maxHeight={320} sx={{ overflowY: 'auto' }}>
			<Typography paragraph variant="h6" fontWeight={400} textAlign={'center'} color={'text.secondary'}>
				Check your inbox.
			</Typography>
			<Typography textAlign={'center'} color={'text.secondary'}>
				Click the link we sent to {email} to complete your reset password.
			</Typography>
			<Typography textAlign={'center'} color={'text.secondary'}>
				Back to <a href="/">Sign in</a>
			</Typography>
		</Box>
	);
};

export default ResetInstructions;
