import { useMutation } from '@tanstack/react-query';
import { Endpoints } from 'enums/endpoints';
import http from 'utils/http';

export const useForgotPassword = () => {
	return useMutation<any, Error, string>(async (email) => {
		const { data } = await http.post(Endpoints.USER_FORGET_PASSWORD + '?Email=' + email);

		return data;
	});
};
