import { JSXElementConstructor, ReactElement, useCallback, useEffect, useState } from 'react';
import Listing, { ChildProps } from '../Listing/Listing';
import { TQueryOptions } from 'types/query-options';

interface Props {
	resourceName: string;
	queryOptions?: TQueryOptions;
	children: ReactElement<ChildProps, string | JSXElementConstructor<any>> | readonly ReactElement<ChildProps, string | JSXElementConstructor<any>>[];
}

interface IState {
	useListingQuery: any | null;
}

const INITIAL_STATE: IState = {
	useListingQuery: null
};

const List: React.FC<Props> = ({ resourceName, queryOptions, children }) => {
	const [state, setState] = useState(INITIAL_STATE);
	const { useListingQuery } = state;

	const setListingFunction = useCallback(async (): Promise<void> => {
		const { useListingQuery } = await import('framework/' + resourceName + '/query/listing');

		setState(() => ({ useListingQuery }));

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		setListingFunction();

		// eslint-disable-next-line
	}, []);

	if (useListingQuery)
		return (
			<Listing resourceName={resourceName} queryOptions={queryOptions} useListingQuery={useListingQuery}>
				{children}
			</Listing>
		);

	return null;
};

export default List;
