import { useMutation } from '@tanstack/react-query';
import { Endpoints } from 'enums/endpoints';
import { IUpdateLanguageInput } from 'types/auth-form';
import http from 'utils/http';

export const useUpdateLanguage = () => {
	return useMutation<any, Error, IUpdateLanguageInput>(async (input) => {
		const { data } = await http.post(`${Endpoints.USER_UPDATE_LANGUAGE}?languageId=${input.languageId}`);
		return data;
	});
};
