import { Control } from 'react-hook-form';

import FormFields from 'components/UI/FormFields';
import { IFormField } from 'types/formFields';
import { InputTypes } from 'enums/input-types';

interface Props {
	control: Control;
	errors: any;
	watch: any;
	setValue: any;
	register: any;
}

const LoginFields: React.FC<Props> = ({ control, errors, watch, setValue, register }) => {
	const fields: IFormField[] = [
		{
			name: 'email',
			placeholder: 'Email',
			type: InputTypes.TEXT
		},
		{
			name: 'password',
			placeholder: 'Password',
			type: InputTypes.PASSWORD
		}
	];

	return (
		<>
			{fields.map((field: any) => (
				<FormFields {...field} key={field.name} control={control} errors={errors} watch={watch} setValue={setValue} register={register} />
			))}
		</>
	);
};

export default LoginFields;
