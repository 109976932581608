import { ITranslation } from 'types/translation';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type State = {
	languageId: number;
	translations: ITranslation[];
};

type Actions = {
	setLanguageId: (languageId: number) => void;
	setTranslations: (translations: ITranslation[]) => void;
};

const useLocalizationStore = create(
	persist<State & Actions>(
		(set) => ({
			languageId: 0,
			translations: [],
			setLanguageId: (payload: number) => set((state) => ({ ...state, languageId: payload })),
			setTranslations: (payload: ITranslation[]) => set((state) => ({ ...state, translations: payload }))
		}),
		{ name: 'localization-store' }
	)
);

export default useLocalizationStore;
