export enum Endpoints {
	BASE_URL = "https://devapi.scofolio.com",
	/** Auth endpoints */
	AUTH_REGISTER = '/Authentication/Register',
	AUTH_LOGIN = '/Authentication/login',
	/** User endpoints */
	USER_UPDATE_FIRST_LAST_NAME = '/User/UpdateFirstNameLastName',
	USER_SAVE_TOPICS = '/User/SaveUserTopics',
	USER_TOPICS = '/User/GetUserTopics',
	USER_ACTIVATE = '/User/Activate',
	USER_FORGET_PASSWORD = '/User/ForgetPassword',
	USER_RESET_PASSWORD = '/User/ChangePassword',
	USER_RESET_PASSWORD_FROM_PROFILE = '/User/ChangePasswordFromProfile',
	USER_UPDATE_LANGUAGE = '/User/UpdateUserLanguage',
	USER_UPDATE_DIALECT = '/User/UpdateUserLanguage',
	USER_FINISHED_SHOWCASE = '/Showcase/HasUserFinishedShowcase',
	USER_TESTS = '/Test/GetUserTests',
	USER_PROFILE_PICTURE = '/Profile/UpdateProfilePic',
	SET_OTP = '/User/InitiateSMSOTP',
	VERIFY_OTP = '/User/VerifySMSOTP',
	VERIFY_LOGIN_OTP = '/User/ContinueLoginOTP',
	/** Topics */
	TOPICS = '/Topic/GetTopics',
	/** Showcases */
	SHOWCASE_BY_ID = '/Showcase/GetShowcaseDetailsById',
	NEXT_SHOWCASE = '/Showcase/GetNextShowcase',
	SKIP_SHOWCASE = '/showcase/SkipShowcase',
	REPORT_SHOWCASE = '/Showcase/ReportShowcase',
	SHOWCASES_HISTORY = '/showcase/GetHistory',
	SHOWCASES_HISTORY_DETAILS = '/showcase/GetHistoryByShowcase',
	SHOWCASES_HISTORY_REPLY = '/Showcase/AddUserQuickReply',
	SHOWCASES_DIALICTS = '/ResponseForm/GetUserAvailableReferenceSentenceSets',
	SENTENCES = '/Showcase/GetReferenceSentences',
	SAVE_USER_SHOWCASE = '/showcase/SaveUserShowCaseResponse',
	/** Profile */
	SETTINGS = '/Setting/GetSiteSettings',
	PROFILE = '/Profile/GetProfile',
	PROFILE_UPDATE = '/Profile/UpdateUserProfile',
	USER_ACCOUNT_UPDATE = '/Profile/UpdateUserAccount',
	USER_PREFERRED_REFERENCE_SENTENCE_SET = '/User/GetPreferedRefenceSentenceSet',
	UPDATE_USER_REFERENCE_SENTENCE_SET = '/ResponseForm/UpdateUserResponseLanguage',
	/** Reasons */
	REASONS = '/Showcase/GetReportReasonList',
	/** Replies */
	REPLIES = '/Showcase/GetQuickReplies',
	/** Progress */
	PROGRESS = '/Progress/get',
	PROGRESS_REPORT = '/Progress/GenerateReport',
	/** Tests */
	TEST = '/Test/GetTestDetils',
	FINALIZE_TEST = '/Test/FinalizeTest',
	SAVE_PROGRESS = '/Test/SaveProgress',
	INITIATE_TEST = '/test/InitiateTest',
	TEST_SUMMARY = '/Test/GetUserTestSummary',
	/** Competencies */
	COMPETENCY = '/Test/GetCompetencyList',
	/** Notifications */
	NOTIFICATION = '/Notification/GetUserNotifications',
	NOTIFICATION_MARK_READ = '/Notification/MarkAsRead',
	NOTIFICATION_MARK_MULTIPLE_READ = '/Notification/MarkMultipleAsRead',
	DELETE_NOTIFICATION = '/Notification/DeleteNotification',
	/** Languages */
	LANGUAGE = '/Language/Get',
	LANGUAGE_FOR_TEST = '/Test/GetLanguageList',
	/** Localization */
	LOCALIZATION = '/Localization/GetTranslation',

	/** StatusForTest */
	STATUS_FOR_TEST = '/Test/GetCompletionStatusList',

	ADD_SUPPORT_TICKET = '/Support/AddSupportTicket',

	/** Privacy and Policy  */
	PRIVACY_AND_POLICY = '/Setting/GetPrivacyPolicy',
	/** Terms and Conditions */
	TERMS_AND_CONDITIONS = '/Setting/GetTerms',
	/** Help */
	GET_HELP = '/User/GetUserGuideForendUser',
	IUpdateProfileResponse = "IUpdateProfileResponse"
}
