import { Box } from '@mui/material';
import { Link } from 'react-router-dom';

interface Props {
	href: string;
	children: React.ReactNode;
}

const LinkWrap: React.FC<Props> = ({ href, children }) => {
	return (
		<Box component={Link} to={href} sx={{ textDecoration: 'none' }}>
			{children}
		</Box>
	);
};

export default LinkWrap;
