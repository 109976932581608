const AccountsBLIconSvg: React.FC = () => {
	return (
		<svg width="99" height="183" viewBox="0 0 99 183" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M96.3934 182.037C76.2883 176.665 54.3341 167.972 46.9822 154.691C20.1728 106.262 56.3866 75.073 75.8424 58.3176C99.0219 38.3549 100.581 24.0265 97.8075 15.5212C94.643 5.81741 84.9902 0.735519 77.3607 0.120361C54.0861 -1.76198 15.4816 18.9255 0 27.7684V29.3678C14.889 20.8784 54.3186 -0.365585 77.2515 1.4808C84.6726 2.07912 93.6707 7.23975 96.5094 15.9448C99.1548 24.0552 97.5618 37.8107 74.952 57.2829C55.2022 74.2917 18.441 105.952 45.7878 155.352C51.7056 166.041 66.9663 175.008 91.165 182.037H96.3934Z"
				fill="#4B2AAD"
			/>
		</svg>
	);
};

export default AccountsBLIconSvg;
