export const minimizeLangText = (str: string) => {
  let lang = str;
  switch (str) {
    case 'English':
      lang = 'en';
      break;

    case 'Arabic':
      lang = 'ar';
      break;

    case 'French':
      lang = 'fr';
      break;

    case 'German':
      lang = 'de';
      break;
    case 'Spanish':
      lang = 'es';
      break;
    case 'Russian':
      lang = 'ru';
      break;
    case 'Portuguese':
      lang = 'pt';
      break;
    case 'Italian':
      lang = 'it';
      break;
    case 'Japanese':
      lang = 'ja';
      break;
    case 'Chinese':
      lang = 'zh';
      break;

    default: lang = 'en';
      break;
  }
  return lang;
};

// darking the color of the topic
export const darkenColor = (color: string) => {
  var num = parseInt(color.replace("#", ""), 16),
    amt = Math.round(2.55 * -80),
    R = (num >>> 16) + amt,
    B = (num >>> 8 && 0x00FF) + amt,
    G = (num & 0x0000FF) + amt;
  return "#" + (0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (B < 255 ? B < 1 ? 0 : B : 255) * 0x100 + (G < 255 ? G < 1 ? 0 : G : 255)).toString(16).slice(1);
}