import { Control } from 'react-hook-form';

import FormFields from 'components/UI/FormFields';
import { IFormField } from 'types/formFields';
import { InputTypes } from 'enums/input-types';
import { Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthCreateInput } from 'types/auth-form';
import useApp from 'hooks/app';

interface Props {
	control: Control;
	errors: any;
	watch: any;
	setValue: any;
	register: any;
	loginCache: AuthCreateInput | null;
	resendOtp: () => void;
}

const OTPFields: React.FC<Props> = ({ control, errors, watch, loginCache, setValue, register, resendOtp }) => {
	const [timer, setTimer] = React.useState<number>(60);
	const [resend, setResend] = React.useState<boolean>(false);
	const { t } = useTranslation();
	const { push } = useApp();

	useEffect(() => {
		if (!loginCache) push('/login');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	useEffect(() => {
		if (!resend) {
			const interval = setInterval(() => {
				if (timer > 0) setTimer((timer) => timer - 1);
			}, 1000);
			return () => clearInterval(interval);
		}
	}, [resend, timer]);

	React.useEffect(() => {
		if (timer === 0 && !resend) {
			setTimer(60);
			setResend(true);
		}
	}, [timer, resend]);

	const fields: IFormField[] = [
		{
			name: 'Token',
			label: 'Enter OTP',
			horizontalLabel: true,
			type: InputTypes.NUMBER,
			placeholder: "******",
			customItem:
				<Stack direction={'row'} justifyContent={'flex-start'} spacing={2} pt={1} useFlexGap>
					{!resend && <Typography variant="body1" fontWeight={400} textAlign={'start'} mb={2} color={'text.secondary'}>
						{t('header.resent_otp_in') + " " + timer + "s"}
					</Typography>}
					<Typography
						variant="body1"
						fontWeight={400}
						textAlign={'start'}
						sx={{
							cursor: resend ? 'pointer' : 'not-allowed',
							'&:hover': resend ? {
								color: '#4F48AD'
							} : {},
							// can click resend only after 60s
							opacity: resend ? 1 : 0.5,
							color: resend ? 'primary.main' : 'text.secondary'
						}}
						mb={2} color={'primary.main'}
						onClick={async () => {
							if (!resend) return;
							await resendOtp();
							setResend(false);
						}}
					>
						{t('button.resend')}
					</Typography>

				</Stack>
		},
	];

	return (
		<>
			{fields.map((field: any) => (
				<FormFields {...field} key={field.name} control={control} errors={errors} watch={watch} setValue={setValue} register={register} />
			))}
		</>
	);
};

export default OTPFields;
