import { FC, memo, Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ActionLoader from 'components/UI/ActionLoader';
import { Pages } from 'enums/pages';
import Dashboard from 'components/dashboard';
import useAuthStore from 'store/auth';

const PrivateRoutes: FC = () => {
	const settings = useAuthStore((state) => state.settings);

	return (
		<Suspense fallback={<ActionLoader position="fixed" />}>
			<Routes>
				<Route path="/" element={<Navigate to={settings.DefaultLandingScreenURL?.replace('/', '') || Pages.SHOWCASES} />} />
				<Route path=":slug/*" element={<Dashboard />} />
			</Routes>
		</Suspense>
	);
};

export default memo(PrivateRoutes);
