import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import Form from 'components/UI/Form';
import { useAlert } from 'context/alertContext';
import { InputTypes } from 'enums/input-types';
import { Pages } from 'enums/pages';
import { Routes } from 'enums/routes';
import { useAddSupPortTicket } from 'framework/users/mutation/support';
import useApp from 'hooks/app';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IFormField } from 'types/formFields';






const Support: React.FC = () => {
	const [formValues, setFormValues] = React.useState<any>({});
	const { t } = useTranslation();
	const { mutateAsync: addSupportTicket } = useAddSupPortTicket();
	const onChange = (name: string, value: any) => setFormValues({ ...formValues, [name]: value });
	const { setAlert } = useAlert();
	const { push } = useApp();

	let basicFields: IFormField[] = [
		{
			name: "Title",
			label: t('header.title') + ":",
			type: InputTypes.TEXT,
			placeholder: t('header.enter_title'),
			horizontalLabel: true,
		},
		{
			name: "Description",
			label: t('header.description') + ":",
			type: InputTypes.TEXTAREA,
			placeholder: t('header.enter_description'),
			horizontalLabel: true,
		},
		{
			name: "Attachment",
			label: t('header.attachments') + ":",
			type: InputTypes.FILE,
			horizontalLabel: true,
		},


	];

	const onSubmit = async () => {
		try {
			const formDate = new FormData();
			formDate.append('Title', formValues.Title);
			formDate.append('Description', formValues.Description);
			if (formValues.Attachment) formDate.append('Attachment', formValues.Attachment);

			const response: any = await addSupportTicket(formDate);
			if (response.Success === 1) {
				setAlert({
					open: true,
					message: response.Message || t('success.successfully_submitted'),
					type: "success"
				});
				setFormValues({});
				push(Routes.DASHBOARD + Pages.SHOWCASES);

			}
			else {
				setAlert({
					open: true,
					message: response.Message || t('Something_went_wrong'),
					type: 'error'
				})
			}
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err.Message || t('Something_went_wrong'),
				type: 'error'
			})
		}
	};

	return (
		<Box border={'1px solid'}
			borderColor={'divider'}
			borderRadius={'8px'}
			sx={{ overflowY: 'auto' }}
			px={3}
		>
			<Stack
				spacing={2}
				my={5}
				mx={5}>
				<Typography variant={'h3'} fontWeight={600}>
					{t('header.request_assistance')}
				</Typography>
				<Form
					fields={basicFields}
					onChange={onChange}
				/>
				<Grid container mt={3} alignItems={'center'} justifyContent={'center'}>
					<Button variant="contained" size="large" sx={{ alignSelf: 'stretch' }}
						disabled={!formValues.Title}
						onClick={onSubmit}>
						{t('button.submit')}
					</Button>
				</Grid>

			</Stack>

		</Box>
	);
};

export default Support;
