export const LogoSVG: React.FC = () => {
	return (
		<svg width={215} height={86} viewBox="0 0 215 86" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="mask0_870_275" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x={12} y={23} width={80} height={40}>
				<path d="M12.3906 23.5751H91.0355V62.8976H12.3906V23.5751Z" fill="white" />
			</mask>
			<g mask="url(#mask0_870_275)">
				<path d="M12.3906 3.89654H91.0355V82.5414H12.3906V3.89654Z" fill="#00B5A2" />
			</g>
			<path
				d="M24.2426 63.4039C22.3559 63.4039 20.704 63.1137 19.288 62.5329C18.1636 62.1332 17.0026 61.3889 15.8043 60.3008C13.7355 58.3769 12.7922 55.9456 12.9742 53.0064V52.4065L19.0708 52.5161H19.5611V52.9527C19.5968 53.8588 19.7959 54.6025 20.1589 55.1827C20.7765 56.2368 22.1377 56.7633 24.2426 56.7633C25.9845 56.7633 27.2366 56.5636 27.9994 56.1633C28.3977 55.9828 28.6697 55.747 28.8145 55.4558C28.8874 55.3472 28.9241 55.2381 28.9241 55.129L28.9779 54.858C28.9779 54.0956 28.379 53.4781 27.1823 53.0064C26.7106 52.8244 26.1665 52.6791 25.55 52.5699C25.0768 52.4613 24.5498 52.3708 23.9695 52.2989C21.7917 52.0812 19.9774 51.6452 18.5267 50.9915C17.0745 50.4112 15.8767 49.6127 14.9333 48.5959C13.6269 47.108 12.9742 45.4028 12.9742 43.4799C12.9742 42.2816 13.192 41.1743 13.628 40.1576C13.9533 39.3606 14.3882 38.6531 14.9333 38.035C16.8929 35.7858 20.0327 34.6609 24.3523 34.6609C26.9283 34.6609 29.1052 35.1688 30.8832 36.1835C32.262 36.9464 33.3699 37.9807 34.2056 39.2866C34.8578 40.2672 35.3108 41.4107 35.5648 42.7166C35.6377 43.1526 35.692 43.5704 35.7282 43.9702V45.1122H29.1414V44.295C29.1052 44.1502 29.0876 44.0054 29.0876 43.8606C28.9785 43.4608 28.8331 43.1339 28.6511 42.88C27.8536 41.7918 26.421 41.2478 24.3523 41.2478C21.3029 41.2478 19.7783 41.8829 19.7783 43.1531C19.7783 43.8058 20.1408 44.3317 20.8664 44.7315C21.6288 45.2032 22.9176 45.5481 24.7329 45.7659C28.906 46.2019 31.9362 47.5264 33.8229 49.7399C35.0931 51.2284 35.7282 52.934 35.7282 54.858V55.2386L35.6744 55.7289C35.6744 56.2006 35.6196 56.636 35.511 57.0343C35.2214 58.1958 34.6773 59.2302 33.8788 60.1374C32.9711 61.2271 31.7009 62.0437 30.0682 62.5867C28.4349 63.1318 26.4934 63.4039 24.2426 63.4039Z"
				fill="white"
			/>
			<path
				d="M61.4358 60.0334C58.7863 62.2842 55.7018 63.4095 52.1824 63.4095C48.2983 63.3739 44.9956 61.9769 42.2732 59.2183C39.5517 56.4602 38.2091 53.1569 38.2453 49.3091C38.281 45.4271 39.6779 42.1244 42.4366 39.4019C45.1947 36.6795 48.498 35.3369 52.3458 35.3741C55.8296 35.4103 58.8778 36.5714 61.4917 38.8578L56.4274 43.8662C55.1945 42.9591 53.8151 42.505 52.29 42.505C50.4033 42.505 48.77 43.1582 47.3912 44.4641C46.0476 45.8077 45.3763 47.441 45.3763 49.3649C45.3044 51.2516 45.9581 52.8849 47.3374 54.2637C48.6433 55.5701 50.276 56.2595 52.2362 56.3324C53.7598 56.3324 55.1391 55.8788 56.3736 54.9712L61.4358 60.0334Z"
				fill="white"
			/>
			<path
				d="M76.845 35.3741C80.7285 35.3369 84.0307 36.6795 86.7521 39.4019C89.5103 42.1606 90.8896 45.4628 90.8896 49.3091C90.9268 53.1931 89.5842 56.4958 86.8618 59.2183C84.1393 61.9769 80.8547 63.3739 77.0084 63.4095C73.1244 63.4452 69.8035 62.1031 67.0454 59.3817C64.2867 56.6593 62.9079 53.3565 62.9079 49.4725C62.8717 45.5905 64.2154 42.2878 66.9378 39.5654C69.6587 36.8072 72.9609 35.4103 76.845 35.3741ZM81.7996 54.2637C83.1412 52.9206 83.8125 51.2878 83.8125 49.3649C83.8125 47.4772 83.1225 45.8434 81.7437 44.4641C80.3644 43.1582 78.7317 42.505 76.845 42.505C74.9583 42.505 73.3431 43.1768 72 44.52C70.6564 45.935 69.9851 47.5682 69.9851 49.4187C69.9851 51.3054 70.6745 52.9387 72.0538 54.3175C73.3969 55.6611 75.0307 56.3324 76.9546 56.3324C78.9142 56.2595 80.5294 55.5701 81.7996 54.2637Z"
				fill="white"
			/>
			<path
				d="M108.63 33.0278C107.795 33.0278 107.088 33.3184 106.508 33.8987C105.927 34.5167 105.637 35.2429 105.637 36.0771V39.0705H111.243V46.6917H105.637V63.405H98.0156V46.6379H93.1147V39.0705H98.0156V33.0278C98.0156 30.9233 98.7593 29.1448 100.248 27.6925C101.736 26.2046 103.532 25.4604 105.637 25.4604H111.243V33.0278H108.63Z"
				fill="#4B2AAD"
			/>
			<path
				d="M127.4 35.3741C131.284 35.3369 134.586 36.6795 137.307 39.4019C140.065 42.1606 141.445 45.4628 141.445 49.3091C141.482 53.1931 140.139 56.4958 137.417 59.2183C134.694 61.9769 131.41 63.3739 127.564 63.4095C123.68 63.4452 120.359 62.1031 117.601 59.3817C114.842 56.6593 113.463 53.3565 113.463 49.4725C113.427 45.5905 114.771 42.2878 117.493 39.5654C120.214 36.8072 123.516 35.4103 127.4 35.3741ZM132.355 54.2637C133.696 52.9206 134.368 51.2878 134.368 49.3649C134.368 47.4772 133.678 45.8434 132.299 44.4641C130.92 43.1582 129.287 42.505 127.4 42.505C125.513 42.505 123.898 43.1768 122.555 44.52C121.212 45.935 120.54 47.5682 120.54 49.4187C120.54 51.3054 121.23 52.9387 122.609 54.3175C123.952 55.6611 125.586 56.3324 127.51 56.3324C129.469 56.2595 131.085 55.5701 132.355 54.2637Z"
				fill="#4B2AAD"
			/>
			<path d="M151.323 25.5688V63.4038H143.7V25.5688H151.323Z" fill="#4B2AAD" />
			<path d="M162.144 25.4604V31.8858H154.576V25.4604H162.144ZM162.199 35.4234V63.405H154.576V35.4234H162.199Z" fill="#4B2AAD" />
			<path
				d="M178.361 35.3741C182.245 35.3369 185.547 36.6795 188.268 39.4019C191.026 42.1606 192.406 45.4628 192.406 49.3091C192.443 53.1931 191.1 56.4958 188.378 59.2183C185.655 61.9769 182.371 63.3739 178.525 63.4095C174.64 63.4452 171.32 62.1031 168.562 59.3817C165.803 56.6593 164.424 53.3565 164.424 49.4725C164.388 45.5905 165.731 42.2878 168.454 39.5654C171.175 36.8072 174.477 35.4103 178.361 35.3741ZM183.316 54.2637C184.657 52.9206 185.329 51.2878 185.329 49.3649C185.329 47.4772 184.639 45.8434 183.26 44.4641C181.881 43.1582 180.248 42.505 178.361 42.505C176.474 42.505 174.859 43.1768 173.516 44.52C172.172 45.935 171.501 47.5682 171.501 49.4187C171.501 51.3054 172.191 52.9387 173.57 54.3175C174.913 55.6611 176.547 56.3324 178.471 56.3324C180.43 56.2595 182.045 55.5701 183.316 54.2637Z"
				fill="#4B2AAD"
			/>
			<path d="M202.254 63.4023H194.631V55.7811H202.254V63.4023Z" fill="#00B5A2" />
		</svg>
	);
};
export const LogoMobileSVG: React.FC = () => {
	return (
		<svg width={150} height={60} viewBox="0 0 215 86" fill="none" xmlns="http://www.w3.org/2000/svg">
			<mask id="mask0_870_275" style={{ maskType: 'luminance' }} maskUnits="userSpaceOnUse" x={12} y={23} width={80} height={40}>
				<path d="M12.3906 23.5751H91.0355V62.8976H12.3906V23.5751Z" fill="white" />
			</mask>
			<g mask="url(#mask0_870_275)">
				<path d="M12.3906 3.89654H91.0355V82.5414H12.3906V3.89654Z" fill="#00B5A2" />
			</g>
			<path
				d="M24.2426 63.4039C22.3559 63.4039 20.704 63.1137 19.288 62.5329C18.1636 62.1332 17.0026 61.3889 15.8043 60.3008C13.7355 58.3769 12.7922 55.9456 12.9742 53.0064V52.4065L19.0708 52.5161H19.5611V52.9527C19.5968 53.8588 19.7959 54.6025 20.1589 55.1827C20.7765 56.2368 22.1377 56.7633 24.2426 56.7633C25.9845 56.7633 27.2366 56.5636 27.9994 56.1633C28.3977 55.9828 28.6697 55.747 28.8145 55.4558C28.8874 55.3472 28.9241 55.2381 28.9241 55.129L28.9779 54.858C28.9779 54.0956 28.379 53.4781 27.1823 53.0064C26.7106 52.8244 26.1665 52.6791 25.55 52.5699C25.0768 52.4613 24.5498 52.3708 23.9695 52.2989C21.7917 52.0812 19.9774 51.6452 18.5267 50.9915C17.0745 50.4112 15.8767 49.6127 14.9333 48.5959C13.6269 47.108 12.9742 45.4028 12.9742 43.4799C12.9742 42.2816 13.192 41.1743 13.628 40.1576C13.9533 39.3606 14.3882 38.6531 14.9333 38.035C16.8929 35.7858 20.0327 34.6609 24.3523 34.6609C26.9283 34.6609 29.1052 35.1688 30.8832 36.1835C32.262 36.9464 33.3699 37.9807 34.2056 39.2866C34.8578 40.2672 35.3108 41.4107 35.5648 42.7166C35.6377 43.1526 35.692 43.5704 35.7282 43.9702V45.1122H29.1414V44.295C29.1052 44.1502 29.0876 44.0054 29.0876 43.8606C28.9785 43.4608 28.8331 43.1339 28.6511 42.88C27.8536 41.7918 26.421 41.2478 24.3523 41.2478C21.3029 41.2478 19.7783 41.8829 19.7783 43.1531C19.7783 43.8058 20.1408 44.3317 20.8664 44.7315C21.6288 45.2032 22.9176 45.5481 24.7329 45.7659C28.906 46.2019 31.9362 47.5264 33.8229 49.7399C35.0931 51.2284 35.7282 52.934 35.7282 54.858V55.2386L35.6744 55.7289C35.6744 56.2006 35.6196 56.636 35.511 57.0343C35.2214 58.1958 34.6773 59.2302 33.8788 60.1374C32.9711 61.2271 31.7009 62.0437 30.0682 62.5867C28.4349 63.1318 26.4934 63.4039 24.2426 63.4039Z"
				fill="white"
			/>
			<path
				d="M61.4358 60.0334C58.7863 62.2842 55.7018 63.4095 52.1824 63.4095C48.2983 63.3739 44.9956 61.9769 42.2732 59.2183C39.5517 56.4602 38.2091 53.1569 38.2453 49.3091C38.281 45.4271 39.6779 42.1244 42.4366 39.4019C45.1947 36.6795 48.498 35.3369 52.3458 35.3741C55.8296 35.4103 58.8778 36.5714 61.4917 38.8578L56.4274 43.8662C55.1945 42.9591 53.8151 42.505 52.29 42.505C50.4033 42.505 48.77 43.1582 47.3912 44.4641C46.0476 45.8077 45.3763 47.441 45.3763 49.3649C45.3044 51.2516 45.9581 52.8849 47.3374 54.2637C48.6433 55.5701 50.276 56.2595 52.2362 56.3324C53.7598 56.3324 55.1391 55.8788 56.3736 54.9712L61.4358 60.0334Z"
				fill="white"
			/>
			<path
				d="M76.845 35.3741C80.7285 35.3369 84.0307 36.6795 86.7521 39.4019C89.5103 42.1606 90.8896 45.4628 90.8896 49.3091C90.9268 53.1931 89.5842 56.4958 86.8618 59.2183C84.1393 61.9769 80.8547 63.3739 77.0084 63.4095C73.1244 63.4452 69.8035 62.1031 67.0454 59.3817C64.2867 56.6593 62.9079 53.3565 62.9079 49.4725C62.8717 45.5905 64.2154 42.2878 66.9378 39.5654C69.6587 36.8072 72.9609 35.4103 76.845 35.3741ZM81.7996 54.2637C83.1412 52.9206 83.8125 51.2878 83.8125 49.3649C83.8125 47.4772 83.1225 45.8434 81.7437 44.4641C80.3644 43.1582 78.7317 42.505 76.845 42.505C74.9583 42.505 73.3431 43.1768 72 44.52C70.6564 45.935 69.9851 47.5682 69.9851 49.4187C69.9851 51.3054 70.6745 52.9387 72.0538 54.3175C73.3969 55.6611 75.0307 56.3324 76.9546 56.3324C78.9142 56.2595 80.5294 55.5701 81.7996 54.2637Z"
				fill="white"
			/>
			<path
				d="M108.63 33.0278C107.795 33.0278 107.088 33.3184 106.508 33.8987C105.927 34.5167 105.637 35.2429 105.637 36.0771V39.0705H111.243V46.6917H105.637V63.405H98.0156V46.6379H93.1147V39.0705H98.0156V33.0278C98.0156 30.9233 98.7593 29.1448 100.248 27.6925C101.736 26.2046 103.532 25.4604 105.637 25.4604H111.243V33.0278H108.63Z"
				fill="#4B2AAD"
			/>
			<path
				d="M127.4 35.3741C131.284 35.3369 134.586 36.6795 137.307 39.4019C140.065 42.1606 141.445 45.4628 141.445 49.3091C141.482 53.1931 140.139 56.4958 137.417 59.2183C134.694 61.9769 131.41 63.3739 127.564 63.4095C123.68 63.4452 120.359 62.1031 117.601 59.3817C114.842 56.6593 113.463 53.3565 113.463 49.4725C113.427 45.5905 114.771 42.2878 117.493 39.5654C120.214 36.8072 123.516 35.4103 127.4 35.3741ZM132.355 54.2637C133.696 52.9206 134.368 51.2878 134.368 49.3649C134.368 47.4772 133.678 45.8434 132.299 44.4641C130.92 43.1582 129.287 42.505 127.4 42.505C125.513 42.505 123.898 43.1768 122.555 44.52C121.212 45.935 120.54 47.5682 120.54 49.4187C120.54 51.3054 121.23 52.9387 122.609 54.3175C123.952 55.6611 125.586 56.3324 127.51 56.3324C129.469 56.2595 131.085 55.5701 132.355 54.2637Z"
				fill="#4B2AAD"
			/>
			<path d="M151.323 25.5688V63.4038H143.7V25.5688H151.323Z" fill="#4B2AAD" />
			<path d="M162.144 25.4604V31.8858H154.576V25.4604H162.144ZM162.199 35.4234V63.405H154.576V35.4234H162.199Z" fill="#4B2AAD" />
			<path
				d="M178.361 35.3741C182.245 35.3369 185.547 36.6795 188.268 39.4019C191.026 42.1606 192.406 45.4628 192.406 49.3091C192.443 53.1931 191.1 56.4958 188.378 59.2183C185.655 61.9769 182.371 63.3739 178.525 63.4095C174.64 63.4452 171.32 62.1031 168.562 59.3817C165.803 56.6593 164.424 53.3565 164.424 49.4725C164.388 45.5905 165.731 42.2878 168.454 39.5654C171.175 36.8072 174.477 35.4103 178.361 35.3741ZM183.316 54.2637C184.657 52.9206 185.329 51.2878 185.329 49.3649C185.329 47.4772 184.639 45.8434 183.26 44.4641C181.881 43.1582 180.248 42.505 178.361 42.505C176.474 42.505 174.859 43.1768 173.516 44.52C172.172 45.935 171.501 47.5682 171.501 49.4187C171.501 51.3054 172.191 52.9387 173.57 54.3175C174.913 55.6611 176.547 56.3324 178.471 56.3324C180.43 56.2595 182.045 55.5701 183.316 54.2637Z"
				fill="#4B2AAD"
			/>
			<path d="M202.254 63.4023H194.631V55.7811H202.254V63.4023Z" fill="#00B5A2" />
		</svg>
	);
};

