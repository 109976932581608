import { TPopup, emptyPopup } from '../types/popup';
import { create } from 'zustand';

type State = {
	popup: TPopup;
};

type Actions = {
	setPopup: (payload: TPopup) => void;
};

const usePopupStore = create<State & Actions>((set) => ({
	popup: emptyPopup,
	setPopup: (payload: TPopup) => set((state) => ({ ...state, popup: payload }))
}));

export default usePopupStore;
