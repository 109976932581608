interface Props {
	active?: boolean;
}

const ProgressSVG: React.FC<Props> = ({ active }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M4 16L11 8L15 11L20 5" stroke={active ? '#000000' : '#62656A'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
			<path d="M3 20H21" stroke={active ? '#000000' : '#62656A'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export default ProgressSVG;
