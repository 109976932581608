const AccountsTRImageSvg: React.FC = () => {
	return (
		<svg width="347" height="161" viewBox="0 0 347 161" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M30.5397 -1C33.3894 15.1502 29.4336 40.4127 10.0802 79.935C-22.7485 146.974 30.8955 174.406 80.7654 152.76C126.72 132.812 135.146 94.4631 170.668 64.1469C226.847 16.2013 309.152 19.101 353.096 25.0556V-1H30.5397Z"
				fill="#4B2AAD"
			/>
		</svg>
	);
};

export default AccountsTRImageSvg;
