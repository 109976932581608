import { useQuery } from '@tanstack/react-query';
import { Endpoints } from 'enums/endpoints';
import { TQueryOptions } from 'types/query-options';
import http from 'utils/http';

export const listing = async ({ queryKey }: any) => {
	const { data } = await http.get(Endpoints.PROFILE);

	return data;
};

export const useListingQuery = (options: TQueryOptions) => useQuery<any, Error>([Endpoints.PROFILE, options], listing);

export const listingSettings = async ({ queryKey }: any) => {
	const { data } = await http.get(Endpoints.SETTINGS);

	return data;
};

export const useListingSettingsQuery = (options: TQueryOptions) => useQuery<any, Error>([Endpoints.SETTINGS, options], listing);
