import { Button, ButtonProps } from "@mui/material";

interface Props extends ButtonProps {
  children: React.ReactNode;
}

const MuiButton: React.FC<Props> = (props: Props) => {
  const { sx, ...rest } = props;
  const styles: typeof sx = { ...sx };

  return (
    <Button sx={styles} {...rest}>
      {props.children}
    </Button>
  );
};

export default MuiButton;
