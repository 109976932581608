import { Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';

import DashboardSVG from 'assets/svgs/dashboard';
import HistorySVG from 'assets/svgs/history';
import ProgressSVG from 'assets/svgs/progress';
import SettingsSVG from 'assets/svgs/settings';
import SurveySVG from 'assets/svgs/survey';
import { Pages } from 'enums/pages';
import { Routes } from 'enums/routes';
import useAuthStore from 'store/auth';
import useApp from 'hooks/app';
import LogoutIcon from '@mui/icons-material/Logout';
import { IMediaQuery } from 'types/media-query';
import useResponsive from 'hooks/responsive';
import PersonIcon from '@mui/icons-material/Person';
import SelectedTopics from '../Selected-topics/Slected-Topics';
import ListItemLink from 'components/UI/list-item-link';
import { useTranslation } from 'react-i18next';
import { Languages } from 'enums/languages';



interface Props {
	activePage: string;
}

const NavMenu: React.FC<Props> = ({ activePage }) => {
	const setSession = useAuthStore((state) => state.setSession);
	const { t, i18n } = useTranslation();
	const { push } = useApp();
	const params: IMediaQuery = { query: 'up', key: 'md' };
	const mdUp = useResponsive(params);

	const handleAdminProfile = async () => {
		push(`${Routes.DASHBOARD + Pages.SETTINGS}`);
	};


	const ICONS = [
		{ id: 1, label: t('header.showcases'), path: Pages.SHOWCASES, icon: (active: boolean) => <DashboardSVG active={active} /> },
		{ id: 2, label: t('header.my_progress'), path: Pages.MY_PROGRESS, icon: (active: boolean) => <ProgressSVG active={active} /> },
		{ id: 3, label: t('header.survey_&_tests'), path: Pages.SURVEY_AND_TESTS, icon: (active: boolean) => <SurveySVG active={active} /> },
		{ id: 4, label: t('header.history'), path: Pages.HISTORY, icon: (active: boolean) => <HistorySVG active={active} /> },
		{ id: 5, label: t('header.settings'), path: Pages.SETTINGS, icon: (active: boolean) => <SettingsSVG active={active} /> }
	];

	const handleChangeDirection = (lang: string) => {
		if (lang === Languages.ENGLISH_FULL_NAME || lang === Languages.ENGLISH) {
			document.documentElement.setAttribute('lang', 'en');
			document.documentElement.setAttribute('dir', 'ltr');
		} else {
			document.documentElement.setAttribute('lang', 'ar');
			document.documentElement.setAttribute('dir', 'rtl');
		}

	}

	const handleLogout = () => {
		localStorage.clear();
		setSession(null);
		localStorage.clear();
		i18n.changeLanguage(Languages.ENGLISH);
		handleChangeDirection(Languages.ENGLISH)
		push(Routes.ROOT);
	};

	return (
		<Box position="relative" px={3} component={'nav'} height={1} borderRight={'1px solid'} borderColor={'divider'} mr={3}
			sx={{
				overflowY: 'auto',
			}}
		>
			<List disablePadding >
				{ICONS.map((item) => (
					<ListItem
						key={item.id}
						sx={{
							cursor: 'pointer',
							bgcolor: activePage === item.path ? '#EEEFF0' : 'transparent',
							borderRadius: '4px',
							transition: 'all 0.1s ease-in-out',
							'&:hover': { bgcolor: '#EEEFF0' }
						}}>
						<ListItemLink to={Routes.DASHBOARD + item.path}>
							<ListItemIcon sx={{ minWidth: 32 }}>{item.icon(activePage === item.path)}</ListItemIcon>
							<ListItemText secondary={item.label} sx={{
								'& .MuiListItemText-secondary': {
									color: activePage === item.path ? 'text.primary' : 'text.secondary',
									float: i18n.language === Languages.ENGLISH_FULL_NAME || i18n.language === Languages.ENGLISH || !mdUp ? 'left' : 'right',
									textAlign: i18n.language === Languages.ENGLISH_FULL_NAME || i18n.language === Languages.ENGLISH || !mdUp ? 'left' : 'right',
								}
							}} />
						</ListItemLink>
					</ListItem>
				))}
				{!mdUp && <>
					<ListItem
						sx={{
							cursor: 'pointer',
							bgcolor: 'transparent',
							borderRadius: '4px',
							transition: 'all 0.1s ease-in-out',
							'&:hover': { bgcolor: '#EEEFF0' }
						}}
						onClick={handleAdminProfile}
					>
						<ListItemIcon sx={{ minWidth: 32 }}>
							<PersonIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText secondary={t('header.profile')} sx={{ '& .MuiListItemText-secondary': { color: 'text.secondary' } }}></ListItemText>
					</ListItem>
					<ListItem onClick={handleLogout}
						sx={{
							cursor: 'pointer',
							bgcolor: 'transparent',
							borderRadius: '4px',
							transition: 'all 0.1s ease-in-out',
							'&:hover': { bgcolor: '#EEEFF0' }
						}}>
						<ListItemIcon sx={{ minWidth: 32 }}>
							<LogoutIcon fontSize="small" />
						</ListItemIcon>
						<ListItemText secondary={t('header.logout')} sx={{ '& .MuiListItemText-secondary': { color: 'text.secondary' } }}></ListItemText>
					</ListItem>
				</>}
			</List>
			{activePage === Pages.SHOWCASES && <SelectedTopics />}
		</Box>
	);
};

export default NavMenu;
