import { SubmitHandler, useForm } from 'react-hook-form';
import { Box, Button, CircularProgress, Grid, IconButton, Stack, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';

import LoginFields from './login-fields';
import RegisterFields from './register-fields';
import ForgetPasswordFields from './forget-password-fields';
import ResetPasswordFields from './reset-password-fields';
import NameFields from './name-fields';
import InterestsFields from './interests-fields';
import useApp from 'hooks/app';
import useAuthStore from 'store/auth';
import { useRegister } from 'framework/auth/mutation/register';
import { useUpdateFirstLastName } from 'framework/auth/mutation/update-first-last-name';
import { useLogin } from 'framework/auth/mutation/login';
import { useSaveUserTopics } from 'framework/users/mutation/saveTopics';
import { FirstLastNameUpdateInput, AuthCreateInput, ILoginResponse, IRegisterResponse, IChangePasswordInput, IResetResponse, IUpdateNameResponse, IUpdateProfileResponse, ISettingsResponse } from 'types/auth-form';
import { LocalStorage } from 'enums/local-storage';
import { Routes } from 'enums/routes';
import { Pages } from 'enums/pages';
import { AUTH_LOGIN, AUTH_REGISTER, DEFAULT_VALIDATION, FINISH_REGISTRATION, FINISH_REGISTRATION_WITH_PASSWORD, RESET_PASSWORD } from 'validations/auth';
import EmailVerificationFields from './email-verification-fields';
import ActivationFields from './activation-fields';
import LinkWrap from 'components/UI/link-wrap';
import { useForgotPassword } from 'framework/users/mutation/forget-password';
import ResetInstructions from './reset-instruction';
import { useResetPassword } from 'framework/users/mutation/reset-password';
import TermsOfService from '../terms-of-service';
import PrivacyPolicy from '../privacy-policy';

import { KeyboardBackspace } from '@mui/icons-material/';
import { useAlert } from 'context/alertContext';
import { listing as profileListing } from 'framework/profile/query/listing';
import { listingSettings as profileSettings } from 'framework/profile/query/listing';
import { useQueryClient } from '@tanstack/react-query';
import { Endpoints } from 'enums/endpoints';
import ListingQuery from 'components/UI/ListingQuery';
import { useTranslation } from 'react-i18next';
import { minimizeLangText } from 'utils/utils';
import OTPFields from './otp-field';
import { useVerifyLoginOTP } from 'framework/users/mutation/verfyLoginOTP';
import Help from 'components/help';
import React from 'react';
import { Profile } from 'types/profile';
import { LogoSVG } from 'assets/svgs/logo';

interface Props {
	slug: string;
	activateToken?: string;
	resetToken?: string;
}

const AuthForm: React.FC<Props> = ({ slug, activateToken, resetToken }) => {
	const session: Profile = useAuthStore((state) => state.session);
	const setSession = useAuthStore((state) => state.setSession);
	const loginCache = useAuthStore((state) => state.loginCache);
	const setLoginCache = useAuthStore((state) => state.setLoginCache);
	const setSettings = useAuthStore((state) => state.setSettings);
	const email = useAuthStore((state) => state.email);
	const setEmail = useAuthStore((state) => state.setEmail);
	const [loading, setLoading] = React.useState(false);
	const { push } = useApp();
	const { setAlert } = useAlert();
	const { mutateAsync: authRegister } = useRegister();
	const { mutateAsync: authLogin } = useLogin();
	const { mutateAsync: authUserUpdate } = useUpdateFirstLastName();
	const { mutateAsync: verifyLoginOtp } = useVerifyLoginOTP();
	const { mutateAsync: saveUserTopics } = useSaveUserTopics();
	const { mutateAsync: onForgetPassword } = useForgotPassword();
	const { mutateAsync: onResetPassword } = useResetPassword();
	const queryClient = useQueryClient();
	const { t, i18n } = useTranslation();

	let defaultEmail: string = '';

	if (defaultEmail === '' && session && session.Email) defaultEmail = session.Email;
	if (defaultEmail === '' && email) defaultEmail = email;

	const defaultValues: any = {
		email: defaultEmail,
		password: '',
		terms: false,
		first_name: '',
		last_name: '',
		topics: new Set<number>()
	};

	const validationSchema = () => {
		if (slug === Pages.LOGIN) return AUTH_LOGIN;
		if (slug === Pages.REGISTER) return AUTH_REGISTER;
		if (slug === Pages.FINISH_REGISTRATION) {
			if (!session) return FINISH_REGISTRATION
			return session.UserInvited === 1 ? FINISH_REGISTRATION_WITH_PASSWORD : FINISH_REGISTRATION;
		}
		if (slug === Pages.RESET_PASSWORD) return RESET_PASSWORD;

		return DEFAULT_VALIDATION;
	};

	const {
		control,
		handleSubmit,
		watch,
		setValue,
		register,
		formState: { errors, isSubmitting }
	} = useForm<any>({ mode: 'onChange', defaultValues, resolver: yupResolver(validationSchema()) });

	const renderTitle = (): string => {
		if (slug === Pages.FINISH_REGISTRATION) return 'Almost there!';
		if (slug === Pages.INTERESTS) return 'What are you interested in?';
		if (slug === Pages.CONFIRM_OTP) return 'Enter the code sent to your phone number';

		return '';
	};

	const renderSubtitle = (): string => {
		if (slug === Pages.FINISH_REGISTRATION) return 'Finish creating your account for the full Scofolio experience.';
		if (slug === Pages.INTERESTS) return 'Choose one or more.';

		return '';
	};

	const renderButtonText = (): string => {
		if (slug === Pages.LOGIN) return 'Login';
		if (slug === Pages.REGISTER) return 'Create account';
		if (slug === Pages.VERIFICATION) return 'OK';
		if (slug === Pages.FORGET_PASSWORD) return 'Continue';
		if (slug === Pages.RESET_PASSWORD) return 'Reset password';
		if (slug === Pages.FINISH_REGISTRATION) return 'Proceed';
		if (slug === Pages.INTERESTS) return 'Join Now';
		if (slug === Pages.ACTIVATE_ACCOUNT) return 'Continue';
		if (slug === Pages.CONFIRM_OTP) return "Verify"

		return '';
	};

	const handleClick = () => {
		if (slug === Pages.LOGIN) push(Routes.ROOT + Pages.REGISTER);
		if (slug === Pages.REGISTER) push(Routes.ROOT);
	};

	const verifyAccount = async (data: any) => {
		try {
			push(Routes.ROOT + Pages.FINISH_REGISTRATION);
		} catch (err: Error | any) {
			console.log(err);
		}
	};

	const handleOpenLeader = () => setLoading(true);
	const handleCloseLeader = () => setLoading(false);

	const getProfile = async () => {
		try {
			const response: IUpdateProfileResponse = await queryClient.fetchQuery([Endpoints.PROFILE, {}], profileListing);
			setSession(response.Data);
			if (response.Data.ErrorLog === 10) {
				localStorage.setItem(LocalStorage.PROFILE, JSON.stringify(response.Data));
				return push(Routes.ROOT + Pages.FINISH_REGISTRATION);
			}
			else if (response.Data.ErrorLog === 11) {
				localStorage.setItem(LocalStorage.PROFILE, JSON.stringify(response.Data));
				return push(Routes.ROOT + Pages.INTERESTS);
			}
			let lang = response.Data.languages.find((lang) => lang.Selected)?.Name || 'English';
			lang = minimizeLangText(lang);
			i18n.changeLanguage(lang);
		} catch (err: Error | any) {
			throw err;
		}
	};
	const getSettings = async (canRedirect: boolean = false, redirectFromFirstRegistration: boolean = false) => {
		try {
			const response: ISettingsResponse = await queryClient.fetchQuery([Endpoints.SETTINGS, {}], profileSettings);
			setSettings(response.Data);
			let redirect = sessionStorage.getItem('redirect');
			if (redirectFromFirstRegistration) push(Routes._DASHBOARD + response.Data.DefaultLandingScreenURL);
			if (canRedirect) {
				if (redirect && redirect !== Routes.ROOT && !redirect.includes(Pages.REGISTER) && !redirect.includes(Pages.LOGIN)
					&& !redirect.includes(Pages.FINISH_REGISTRATION) && !redirect.includes(Pages.INTERESTS) && !redirect.includes(Pages.VERIFICATION) && !redirect.includes(Pages.ACTIVATE_ACCOUNT) && !redirect.includes(Pages.CONFIRM_OTP) && !redirect.includes(Pages.FORGET_PASSWORD) && !redirect.includes(Pages.RESET_PASSWORD) && !redirect.includes(Pages.RESET_INSTRUCTIONS) && !redirect.includes(Pages.PRIVACY_POLICY) && !redirect.includes(Pages.TERMS_OF_SERVICE)
				) {
					sessionStorage.removeItem('redirect');
					push(redirect);
				} else push(Routes._DASHBOARD + response.Data.DefaultLandingScreenURL || Pages.SHOWCASES);
			}
		} catch (err: Error | any) {
			throw err;
		}
	};

	const login = async (data: any) => {
		try {
			handleOpenLeader()
			const input: AuthCreateInput = { Email: data.email, Password: data.password };
			setLoginCache(input);
			const response: ILoginResponse = await authLogin(input);
			handleCloseLeader()

			if (response.Error === 0) {
				localStorage.setItem(LocalStorage.ACCESS_TOKEN, response.accessToken);
				localStorage.setItem(LocalStorage.REFRESH_TOKEN, response.refreshToken);
				localStorage.setItem(LocalStorage.PROFILE, JSON.stringify(response.Data));
				await getProfile();
				await getSettings(true);
			} else if (response.Error === 8) {
				localStorage.setItem(LocalStorage.PROFILE, JSON.stringify(response.Data));
				push(Routes.ROOT + Pages.CONFIRM_OTP);
			} else if (response.Error === 10) {
				localStorage.setItem(LocalStorage.ACCESS_TOKEN, response.accessToken);
				localStorage.setItem(LocalStorage.REFRESH_TOKEN, response.refreshToken);
				localStorage.setItem(LocalStorage.PROFILE, JSON.stringify(response.Data));
				await getProfile();
				setEmail(response.Data);
				push(Routes.ROOT + Pages.FINISH_REGISTRATION);
			}
			else if (response.Error === 11) {
				localStorage.setItem(LocalStorage.ACCESS_TOKEN, response.accessToken);
				localStorage.setItem(LocalStorage.REFRESH_TOKEN, response.refreshToken);
				localStorage.setItem(LocalStorage.PROFILE, JSON.stringify(response.Data));

				setEmail(response.Data);
				push(Routes.ROOT + Pages.INTERESTS);
			}
			else {
				setAlert({
					open: true,
					message: response.Message || t('error.serverError'),
					type: 'error'
				})
			}
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err.Message || t('error.serverError'),
				type: 'error'
			});
			handleCloseLeader()
		}
	};

	const signUp = async (data: any) => {
		try {
			handleOpenLeader();
			const input: AuthCreateInput = { Email: data.email, Password: data.password };
			const response: IRegisterResponse = await authRegister(input);
			handleCloseLeader();
			if (response.Success) {
				setEmail(data.email);

				push(Routes.ROOT + Pages.VERIFICATION);
			} else {
				setAlert({
					open: true,
					message: response.Message || t('error.serverError'),
					type: 'error'
				})
			}
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err.Message || t('error.serverError'),
				type: 'error'
			});
			handleCloseLeader();
		}
	};

	const sendForgetPasswordCode = async (data: any) => {
		try {
			handleOpenLeader();
			const response = await onForgetPassword(data.email);
			handleCloseLeader();
			if (response.Success === 1) {
				setEmail(data.email);

				push(Routes.ROOT + Pages.RESET_INSTRUCTIONS);
			} else {
				setAlert({
					open: true,
					message: response.Message || t('error.serverError'),
					type: 'error'
				})
			}
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err.Message || t('error.serverError'),
				type: 'error'
			});
			handleCloseLeader();
		}
	};

	const sendResetPassword = async (data: any) => {
		try {
			handleOpenLeader();
			const input: IChangePasswordInput = { NewPassword: data.NewPassword, Token: resetToken! };
			handleCloseLeader();
			try {
				const response: IResetResponse = await onResetPassword(input);

				if (response.Success === 1) push(Routes.ROOT);
				else {
					setAlert({ open: true, message: response.Message || t('error.serverError'), type: 'error' });
				}
			} catch (err: Error | any) {
				setAlert({ open: true, message: err.Message || t('error.serverError'), type: 'error' });
			}
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err.Message || t('error.serverError'),
				type: 'error'
			});
			handleCloseLeader();
		}
	};

	const finishRegistration = async (data: any) => {
		try {
			handleOpenLeader();
			const input: FirstLastNameUpdateInput = { FirstName: data.first_name, LastName: data.last_name, Email: data.email };
			if (data.NewPassword) input.Password = data.NewPassword;
			handleCloseLeader();
			const response: IUpdateNameResponse = await authUserUpdate(input);

			if (response.Success === 1) {
				await getProfile();
				await getSettings();
				// const updatedSession = { ...session, FirstName: response.Data.FirstName, LastName: response.Data.LastName, Name: `${data.first_name} ${data.last_name}` };
				// setSession(updatedSession);
				push(Routes.ROOT + Pages.INTERESTS);
			} else {
				setAlert({
					open: true,
					message: response.Message || t('error.serverError'),
					type: 'error'
				});
				return;
			}
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err.Message || t('error.serverError'),
				type: 'error'
			});
			handleCloseLeader();
		}
	};
	const verifyOtp = async (data: any) => {
		try {
			handleOpenLeader();
			let user = JSON.parse(localStorage.getItem(LocalStorage.PROFILE) || '{}');
			const input: any = {
				Token: data.Token,
				userId: user.Id
			};

			const response: ILoginResponse = await verifyLoginOtp(input);
			handleCloseLeader();
			if (response.Error === 0) {
				localStorage.setItem(LocalStorage.ACCESS_TOKEN, response.accessToken);
				localStorage.setItem(LocalStorage.REFRESH_TOKEN, response.refreshToken);
				await getProfile();
				await getSettings(true);
			} else {
				setAlert({
					open: true,
					message: response.Message || t('error.serverError'),
					type: 'error'
				});
				return;
			}
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err.Message || t('error.serverError'),
				type: 'error'
			});
			handleCloseLeader();
		}
	};

	const selectTopics = async (data: any) => {
		try {
			if (data.topics.size === 0) {
				setAlert({
					open: true,
					message: 'Please select at least one topic',
					type: 'error'
				})

				return;
			}
			handleOpenLeader();

			await saveUserTopics(data.topics);
			handleCloseLeader();
			await getProfile();
			await getSettings(false, true);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err.Message || t('error.serverError'),
				type: 'error'
			});
			handleCloseLeader();
		}
	};

	const activateAccount = () => {
		push(Routes.ROOT + Pages.FINISH_REGISTRATION);
	};

	const onSubmit: SubmitHandler<any> = (data: any) => {
		if (slug === Pages.LOGIN) return login(data);
		else if (slug === Pages.REGISTER) return signUp(data);
		else if (slug === Pages.VERIFICATION) return verifyAccount(data);
		else if (slug === Pages.FORGET_PASSWORD) return sendForgetPasswordCode(data);
		else if (slug === Pages.RESET_PASSWORD) return sendResetPassword(data);
		else if (slug === Pages.FINISH_REGISTRATION) return finishRegistration(data);
		else if (slug === Pages.INTERESTS) return selectTopics(data);
		else if (slug === Pages.ACTIVATE_ACCOUNT) return activateAccount();
		else if (slug === Pages.CONFIRM_OTP) return verifyOtp(data);
		else console.log('Not implemented');
	};

	const resendOtp = async () => {
		try {
			if (!loginCache) return;
			const response: any = await authLogin(loginCache);
			if (response.Error === 0 || response.Error === 8) {
				setAlert({
					open: true,
					message: t('success.otpSent'),
					type: 'success'
				});
			} else {
				setAlert({
					open: true,
					message: response.Message || t('error.serverError'),
					type: 'error'
				});
			}
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err.Message || t('error.serverError'),
				type: 'error'
			});
		}
	}

	return (
		<>
			{(slug === Pages.PRIVACY_POLICY || slug === Pages.TERMS_OF_SERVICE) && (
				<IconButton sx={{ position: 'fixed', top: 24, left: 24, bgcolor: 'action.hover' }} onClick={() => push(Routes.ROOT + Pages.REGISTER)}>
					<KeyboardBackspace />
				</IconButton>
			)}

			<Stack width={1} height={1}>
				{(slug === Pages.LOGIN || slug === Pages.REGISTER || slug === Pages.CONFIRM_OTP) && (
					<Grid container justifyContent={'flex-end'} alignItems={'center'} pt={{ xs: 2, lg: 4 }} pb={{ xs: 4, lg: 10 }}>
						{slug !== Pages.CONFIRM_OTP && (<>
							<Typography>{slug === Pages.LOGIN ? 'Don’t have an account yet?' : slug === Pages.REGISTER ? 'Already have an account ?' : ''}</Typography>
							<Button variant="outlined" size="small" color="inherit" onClick={handleClick} sx={{ ml: 2 }}>
								{slug === Pages.LOGIN ? 'Create account' : slug === Pages.REGISTER ? 'Log in' : ''}
							</Button>
						</>)}
					</Grid>
				)}
				<Box width={1} height={1} justifySelf={'center'}>
					<Stack width={1} height={1} justifyContent={'center'}>
						<Box alignSelf={'center'} mb={2}>
							<LogoSVG />
						</Box>
						{(slug === Pages.LOGIN || slug === Pages.REGISTER || slug === Pages.CONFIRM_OTP) && (
							<Typography textAlign={'center'} color="text.secondary" fontWeight={300}>
								Identify, map and manage your innovation potential
							</Typography>
						)}
						{(slug === Pages.FINISH_REGISTRATION || slug === Pages.INTERESTS) && (
							<Box mt={2}>
								<Typography variant="h5" textAlign={'center'} paragraph>
									{renderTitle()}
								</Typography>
								<Typography textAlign={'center'} paragraph>
									{renderSubtitle()}
								</Typography>
							</Box>
						)}
						{(slug === Pages.CONFIRM_OTP) && (
							<Box mt={2}>
								<Typography variant="h5" textAlign={'center'} paragraph>
									{renderTitle()}
								</Typography>
							</Box>
						)}
						<Box position={'relative'} height={1} sx={{ overflowY: 'auto' }} pb={3}>
							<Box component="form" onSubmit={handleSubmit(onSubmit)} px={{ xs: 3, lg: 6 }} pt={{ xs: 2, lg: 4 }} sx={{ a: { color: 'inherit' } }} height={300}>
								{slug === Pages.LOGIN && <LoginFields control={control} watch={watch} register={register} setValue={setValue} errors={errors} />}
								{slug === Pages.REGISTER && <RegisterFields control={control} watch={watch} register={register} setValue={setValue} errors={errors} />}
								{slug === Pages.FINISH_REGISTRATION && <NameFields control={control} watch={watch} register={register} setValue={setValue} errors={errors} />}
								{slug === Pages.INTERESTS && (
									<ListingQuery resourceName="topics">
										<InterestsFields control={control} watch={watch} register={register} setValue={setValue} errors={errors} />
									</ListingQuery>
								)}
								{slug === Pages.VERIFICATION && <EmailVerificationFields />}
								{slug === Pages.ACTIVATE_ACCOUNT && <ActivationFields token={activateToken} />}
								{slug === Pages.FORGET_PASSWORD && <ForgetPasswordFields control={control} watch={watch} register={register} setValue={setValue} errors={errors} />}
								{slug === Pages.RESET_INSTRUCTIONS && <ResetInstructions />}
								{slug === Pages.RESET_PASSWORD && <ResetPasswordFields control={control} watch={watch} register={register} setValue={setValue} errors={errors} />}
								{slug === Pages.CONFIRM_OTP && <OTPFields control={control} watch={watch} register={register} setValue={setValue} errors={errors} resendOtp={resendOtp} loginCache={loginCache} />}
								{slug === Pages.LOGIN && (
									<Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'} textAlign={'end'} useFlexGap>
										<LinkWrap href={Routes.ROOT + Pages.FORGET_PASSWORD}>
											<Typography color="primary.main" fontWeight={500} >
												Forgot password?
											</Typography>
										</LinkWrap>
									</Stack>
								)}
								{slug === Pages.TERMS_OF_SERVICE && <TermsOfService />}
								{slug === Pages.PRIVACY_POLICY && <PrivacyPolicy />}
								{slug === Pages.HELP && <Help />}
								{slug !== Pages.RESET_INSTRUCTIONS && slug !== Pages.PRIVACY_POLICY && slug !== Pages.TERMS_OF_SERVICE && slug !== Pages.VERIFICATION && (
									<Stack spacing={2} mt={2}>
										<Grid container justifyContent={'center'} >
											<Button color="secondary" variant="contained" size="large" type="submit"
												sx={{
													display: slug === Pages.FINISH_REGISTRATION && !session ? 'none' : '',
												}}
												disabled={isSubmitting} disableElevation>
												{renderButtonText()}
												<CircularProgress
													size={10}
													sx={{ mx: 2, display: loading ? "" : "none" }}
												/>
											</Button>
										</Grid>
										{(slug === Pages.FORGET_PASSWORD || (slug === Pages.FINISH_REGISTRATION && !session)) && <Typography textAlign={'center'} color={'text.secondary'}>
											Back to <a href="/">Sign in</a>
										</Typography>}
									</Stack>
								)}
							</Box>
						</Box>
					</Stack>
				</Box>
				{(slug === Pages.LOGIN || slug === Pages.REGISTER || slug === Pages.CONFIRM_OTP) && (
					<Typography textAlign={'center'} color="text.secondary" variant="body2" my={{ xs: 4, lg: 8 }}>
						NOTE: This system is accessible only to registered organizations. Please ensure that you have an email address associated with the organization or have already been invited to join.
					</Typography>
				)}
			</Stack>

		</>
	);
};

export default AuthForm;
