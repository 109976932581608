import { Endpoints } from 'enums/endpoints';
import axios from 'axios';


export const getPrivacyAndPolicy = async () => {

	const { data } = await axios.get(`${Endpoints.BASE_URL}/api${Endpoints.PRIVACY_AND_POLICY}`);

	return data;
};
export const getTermsAndConditions = async () => {

	const { data } = await axios.get(`${Endpoints.BASE_URL}/api${Endpoints.TERMS_AND_CONDITIONS}`);

	return data;
};
export const getHelp = async () => {

	const { data } = await axios.get(`${Endpoints.BASE_URL}/api${Endpoints.GET_HELP}`, {
		headers: {
			Authorization: `Bearer ${localStorage.getItem('access_token')}`,
		},
	});

	return data;
};


