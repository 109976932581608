import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { Controller } from "react-hook-form";
import { IFormField } from "types/formFields";
import { IOption } from "types/option";

interface Props extends IFormField {
  control: any;
  watch: any;
  defaultValue?: IOption;
  hidden?: boolean;
}

const RHAutoComplete: React.FC<Props> = ({
  label,
  name,
  control,
  watch,
  multiple,
  options,
  onChange,
  defaultValue,
  hidden,
  horizontalLabel,
  placeholder,
  errorMessage,
  id
}) => {
  const value = watch(name) || defaultValue || [];
  const handleChange = (e: any) => {
    onChange && onChange(e);
  }

  const getOptionDisabled = (option: IOption) => {
    if (!multiple) {
      return option?.value! === value?.value!;
    }
    if (!value) return false;
    return value.some((selectedOption: IOption) => selectedOption.label === option.label);
  };

  return (

    <Controller

      control={control}
      name={name}
      render={({ field: { ref, onChange, ...field } }) => (
        <Grid container alignItems={'center'} hidden={hidden} id={id}>
          <Grid item md={12} lg={horizontalLabel ? 12 : 3} sm={12} xs={12}>
            {label && <Typography variant="body2" fontSize={15} fontWeight={600}
              textAlign={'start'} minWidth={'16ch'}
              alignItems={'center'}
            >
              {label} {errorMessage && <span style={{
                color: 'red',
                fontSize: '20px',
              }}>*</span>}
            </Typography>}
          </Grid>
          <Grid item md={12} lg={label ? horizontalLabel ? 12 : 9 : 12} sm={12} xs={12}>
            <Autocomplete
              sx={{ width: '100%' }}
              multiple={multiple ? true : false}
              limitTags={3}
              options={options!}
              defaultValue={defaultValue || null}
              getOptionDisabled={getOptionDisabled}
              isOptionEqualToValue={(option: any, value) => option.value === value?.value!}
              filterOptions={(options, state) => {
                if (state.inputValue?.length! === 0) return options;
                return options.filter((option: IOption) =>
                  option?.label!
                    .toLowerCase()
                    .includes(state.inputValue.toLowerCase())
                );
              }}
              onChange={(_, data) => {
                onChange(data)
                handleChange(data);
              }}
              getOptionLabel={(option: IOption) => option.label || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...field}
                  inputRef={ref}
                  InputLabelProps={{ children: "" }}
                  fullWidth
                  placeholder={placeholder}
                  size="small"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      bgcolor: 'common.white',
                    },
                  }}
                />
              )}
            />
            {/* {errorMessage && (
              <Typography variant="caption" color="error.main">
                {errorMessage}
              </Typography>
            )} */}
          </Grid>
        </Grid>

      )}
    />
  );
};

export default RHAutoComplete;
