import { useMutation } from '@tanstack/react-query';
import { Endpoints } from 'enums/endpoints';
import http from 'utils/http';

export const useAddSupPortTicket = () => {
	return useMutation<any, Error, any>(async (input) => {
		const { data } = await http.post(Endpoints.ADD_SUPPORT_TICKET, input);
		return data;
	});
};
