import { Divider, Stack, Typography } from "@mui/material";
import TopicsListForShowcase from "./partials/topics-list/topics-list_for_showcase";
import usePopupStore from "store/popup";
import AddTopicPopupForShowcase from "./partials/add-topics-popup_for_showcase/addTopicPopupForShowcase";
import MuiButton from "components/UI/MuiButton";
import { useTranslation } from "react-i18next";



const SelectedTopics: React.FC = () => {
	const { t } = useTranslation();
	const setPopup = usePopupStore((state) => state.setPopup);

	const handlePopup = (title: string, content: React.ReactNode) => {
		setPopup({ open: true, title, content });
	};
	return (
		<>
			<Divider sx={{ my: 1 }} />
			<Stack direction={'row'} justifyContent={'space-between'}
				alignItems={'center'} sx={{ mb: 1, mt: 4 }}>
				<Typography variant="body2" fontWeight={700}>
					{t('header.selected_topics')}
				</Typography>
				<MuiButton
					variant={'text'}
					size="small"
					onClick={() => handlePopup(t('header.add_topics'), <AddTopicPopupForShowcase />)}
					sx={{
						textTransform: 'none',
						textDecoration: 'underline',
						fontWeight: 700,
						color: 'info.main',
						'&:hover': {
							bgcolor: 'transparent',
						},
					}}>
					{t('button.edit')}
				</MuiButton>
			</Stack>
			<TopicsListForShowcase />
		</>
	);
};

export default SelectedTopics;
