import { ILanguage } from 'types/language';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import useLocalizationStore from 'store/localization';
import { useEffect } from 'react';
import { useUpdateLanguage } from 'framework/users/mutation/update-language';
import { useAlert } from 'context/alertContext';
import { useTranslation } from 'react-i18next';
import { Languages } from 'enums/languages';
import { Profile } from 'types/profile';
import useAuthStore from 'store/auth';
import { minimizeLangText } from 'utils/utils';

const LanguageSwitcher = () => {
	const session: Profile = useAuthStore((state) => state.session);
	const languages: ILanguage[] = session.languages;

	let defaultLanguage = session.languages.find((lang) => lang.Selected) || languages[0];
	const { mutateAsync: updateUpdateLanguage } = useUpdateLanguage();
	const { setAlert } = useAlert();
	const { setLanguageId } = useLocalizationStore();
	const { t, i18n } = useTranslation();
	// // Setting languageId to the first language.Id if the local storage has no `localization-store`
	// useEffect(() => {
	// 	const localizationStore = localStorage.getItem('localization-store');
	// 	const parsedLocaleStorage = JSON.parse(localizationStore!);

	// 	if (parsedLocaleStorage.state.languageId === 0) {
	// 		setLanguageId(languages[0].Id);
	// 	}

	// 	// eslint-disable-next-line
	// }, [languages]);

	// // Initializing search filters
	// useEffect(() => {
	// 	const initializeData = async () => {
	// 		try {
	// 			const response = await queryClient.fetchQuery<ILocalizationResponse, Error>([Endpoints.LOCALIZATION, { languageId }], localizationList);
	// 			setTranslations(response.Data);
	// 		} catch (error) {
	// 			console.error(error);
	// 		}
	// 	};

	// 	initializeData();

	// 	// eslint-disable-next-line
	// }, [languageId]);

	// Setting <html> lang and dir attributes

	const handleChangeDirection = (lang: string) => {
		if (lang === Languages.ENGLISH_FULL_NAME || lang === Languages.ENGLISH) {
			document.documentElement.setAttribute('lang', 'en');
			document.documentElement.setAttribute('dir', 'ltr');
		} else {
			document.documentElement.setAttribute('lang', 'ar');
			document.documentElement.setAttribute('dir', 'rtl');
		}
	};
	useEffect(() => {
		handleChangeDirection(i18n.language);
		// eslint-disable-next-line
	}, []);

	const handleChange = async (event: SelectChangeEvent<number>) => {
		let lang: string = session.languages.find((lang) => lang.Id === event.target.value)?.Name || 'English';
		lang = minimizeLangText(lang);
		try {
			await updateUpdateLanguage({ languageId: event.target.value as number });
			setLanguageId(event.target.value as number);
			i18n.changeLanguage(lang);
			window.location.reload();
			// handleChangeDirection(lang);
			// if (slug === Pages.SHOWCASES) {
			// 	updateNextShowcaseLang(true);
			// }
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err.Message || t('error.serverError'),
				type: 'error'
			});
		}
	};

	return (
		<Select
			id="language-switcher"
			size="small"
			defaultValue={defaultLanguage.Id! || languages[0].Id!}
			onChange={handleChange}
			sx={{ borderRadius: '100px' }}
			MenuProps={{
				sx: {
					mt: 1,
					'& .MuiPaper-root': {
						backgroundColor: 'white',
						borderRadius: '16px',
						boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
						'.Mui-selected': {
							backgroundColor: '#00B5A2 !important',
							color: 'white !important',
							borderRadius: '8px'
						}
					},
				}
			}}>
			{languages.map((language) => (
				<MenuItem key={language.Id} value={language.Id}>
					{language.Name === 'Arabic' ? Languages.ARABIC_FULL_NAME : language.Name}
				</MenuItem>
			))}
		</Select>
	);
};

export default LanguageSwitcher;
