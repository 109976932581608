import { ThemeOptions } from '@mui/material/styles';
import { Languages } from 'enums/languages';

const lightThemeOptions = (lang: string) => {
	const options: ThemeOptions = {
		direction: 'ltr',
		palette: {
			primary: {
				// light: "#933346",
				main: '#00b5a2'
				// dark: "#540010",
			},
			secondary: {
				// light: "#333333",
				main: '#4b2aad'
				// dark: "#000000",
			},
			text: {
				primary: '#0B0C0C',
				secondary: '#6F7F95'
			},
			background: {
				default: '#ffffff',
				paper: '#EBEBEB'
			},
			info: {
				main: '#005EA5'
			},
			divider: '#05060F14'
		},
		shape: {
			borderRadius: 0
		},
		typography: {
			button: {
				textTransform: 'none'
			},
			fontFamily:
				lang === Languages.ARABIC
					? ["Tajawal_Black", "Tajawal_Bold", "Tajawal_ExtraBold", "Tajawal_ExtraLight", "Tajawa_Lightl", "Tajawal_Medium", "Tajawal_Regular", "serif"].join(",")
					: ["roboto", "sans-serif"].join(","),
		},
		components: {
			MuiTextField: {
				styleOverrides: {
					root: {
						borderRadius: 8
					}
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						borderRadius: 8
					}
				}
			},
			MuiButton: {
				defaultProps: {
					disableElevation: true
				},
				styleOverrides: {
					root: {
						borderRadius: 8
					}
					// contained: {
					// 	color: palette.background?.default
					// },
					// containedPrimary: {
					// 	background: linearGradient((palette.primary as SimplePaletteColorOptions)?.light ?? '', (palette.primary as SimplePaletteColorOptions)?.dark ?? '')
					// },
					// containedSecondary: {
					// 	background: linearGradient((palette.secondary as SimplePaletteColorOptions)?.light ?? '', (palette.secondary as SimplePaletteColorOptions)?.dark ?? '')
					// }
				}
			},
			MuiButtonGroup: {
				defaultProps: {
					disableElevation: true
				}
			},
			MuiCard: {
				defaultProps: {
					elevation: 0
				},
				styleOverrides: {
					root: {
						borderRadius: '10px',
						marginBottom: 16,
						filter: 'drop-shadow(0px 3px 4px rgba(227, 227, 227, 0.29))',
						'&:last-of-type': {
							marginBottom: 0
						}
					}
				}
			},
			MuiCheckbox: {
				defaultProps: {
					disableRipple: true
				},
				styleOverrides: {
					root: { width: 56, height: 56 }
				}
			},
			MuiFormControl: {
				styleOverrides: {
					root: {
						display: 'block',
						marginBottom: 16
					}
				}
			}
		}
	};

	return options;
}

export default lightThemeOptions;
