import { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';

import useLoading from 'hooks/loading';
import ActionLoader from 'components/UI/ActionLoader';
import useAuthStore from 'store/auth';
import { useUserActivate } from 'framework/users/mutation/activate';
import { LocalStorage } from 'enums/local-storage';
import { IUpdateProfileResponse, IVerifyResponse } from 'types/auth-form';
import { useAlert } from 'context/alertContext';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import { listing as profileListing } from 'framework/profile/query/listing';
import { Endpoints } from 'enums/endpoints';
import { minimizeLangText } from 'utils/utils';
import i18n from 'i18n';
import useApp from 'hooks/app';
import { Routes } from 'enums/routes';
import { Pages } from 'enums/pages';

interface Props {
	token?: string;
}

const ActivationFields: React.FC<Props> = ({ token }) => {
	const [messageText, setMessageText] = useState<string>('');
	const setSession = useAuthStore((state) => state.setSession);
	const { loading, setLoading } = useLoading();
	const { mutateAsync: onUserActivate } = useUserActivate();
	const { setAlert } = useAlert();
	const { t } = useTranslation();
	let queryClient = useQueryClient();
	const { push } = useApp();

	const handleError = (errMsg: string) => {
		setAlert({
			open: true,
			message: errMsg || t('error.serverError'),
			type: 'error'
		})
		setMessageText(errMsg);
		setLoading(false);
	};

	const activateUser = useCallback(async () => {
		try {
			if (!token) {
				handleError('Please check your email for activation link.');
				return push('/login');
			};
			const response: IVerifyResponse = await onUserActivate(encodeURIComponent(token!));

			if (response.Success === 1) {
				localStorage.setItem(LocalStorage.ACCESS_TOKEN, response.AccessToken);
				localStorage.setItem(LocalStorage.REFRESH_TOKEN, response.RefreshToken);
				setLoading(false);
				await getProfile();
			} else handleError(response.Message!);
		} catch (err: Error | any) {
			handleError(err.message);
		}

		// eslint-disable-next-line
	}, []);

	const getProfile = async () => {
		try {
			const response: IUpdateProfileResponse = await queryClient.fetchQuery([Endpoints.PROFILE, {}], profileListing);
			setSession(response.Data);
			if (response.Data.ErrorLog === 10) {
				localStorage.setItem(LocalStorage.PROFILE, JSON.stringify(response.Data));
				return push(Routes.ROOT + Pages.FINISH_REGISTRATION);
			}
			else if (response.Data.ErrorLog === 11) {
				localStorage.setItem(LocalStorage.PROFILE, JSON.stringify(response.Data));
				return push(Routes.ROOT + Pages.INTERESTS);
			}
			let lang = response.Data.languages.find((lang) => lang.Selected)?.Name || 'English';
			lang = minimizeLangText(lang);
			i18n.changeLanguage(lang);
		} catch (err: Error | any) {
			throw err;
		}
	};

	useEffect(() => {
		if (token) activateUser();
		else handleError('Invalid token');

		// eslint-disable-next-line
	}, []);

	return (
		<Box mb={6} maxHeight={320} sx={{ overflowY: 'auto' }}>
			<Grid container height={120} alignItems={'center'} justifyContent={'center'}>
				{loading && <ActionLoader position="relative" />}
				{!loading && messageText.length === 0 && (
					<Typography variant="h6" fontWeight={400} textAlign={'center'} color={'text.secondary'}>
						Congratulation your account has been activated.
					</Typography>
				)}
				{!loading && messageText.length > 0 && (
					<Typography variant="h6" fontWeight={400} textAlign={'center'} color={'text.secondary'}>
						{messageText}
					</Typography>
				)}
			</Grid>
		</Box>
	);
};

export default ActivationFields;
