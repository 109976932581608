export enum Pages {
	LOGIN = 'login',
	REGISTER = 'register',
	FINISH_REGISTRATION = 'finish-registration',
	INTERESTS = 'interests',
	FORGET_PASSWORD = 'forget-password',
	CONFIRM_OTP = 'confirm-otp',
	RESET_PASSWORD = 'reset-password',
	VERIFICATION = 'verification',
	ACTIVATE_ACCOUNT = 'activate-account',
	RESET_INSTRUCTIONS = 'reset-instructions',
	SHOWCASES = 'showcases',
	MY_PROGRESS = 'my-progress',
	SURVEY_AND_TESTS = 'survey-and-tests',
	HISTORY = 'history',
	SETTINGS = 'settings',
	TERMS_OF_SERVICE = 'terms-of-service',
	PRIVACY_POLICY = 'privacy-policy',
	HELP = 'help',
	SUPPORT = 'support',
}
