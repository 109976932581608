import axios, { AxiosInstance, AxiosError } from 'axios';
import { LocalStorage } from 'enums/local-storage';

const instance: AxiosInstance = axios.create({ baseURL: process.env.REACT_APP_BACKEND_URL, timeout: 30000 });

instance.interceptors.request.use(
	(config: any) => {
		const accessToken: string | null = localStorage.getItem(LocalStorage.ACCESS_TOKEN);

		if (accessToken) config.headers!.Authorization = `Bearer ${accessToken}`;

		return config;
	},
	(error: AxiosError) => {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(res) => {
		return res;
	},
	async (err: AxiosError) => {
		const originalConfig: any = err.config;

		if (originalConfig.url !== '/Authentication/GenerateRefreshToken' && err.response) {
			// Access Token was expired
			if (err.response.status === 401 && !originalConfig._retry) {
				originalConfig._retry = true;

				try {
					const refreshToken: string | null = localStorage.getItem(LocalStorage.REFRESH_TOKEN);
					const { data } = await instance.get('/Authentication/GenerateRefreshToken?refreshToken=' + refreshToken!);

					localStorage.setItem(LocalStorage.ACCESS_TOKEN, data);
					localStorage.setItem(LocalStorage.REFRESH_TOKEN, data);

					return instance(originalConfig);
				} catch (_error) {
					// Logging out the user by removing all the tokens from local
					localStorage.clear();
					// Redirecting the user to the landing page
					// window.location.href = window.location.origin;
					return Promise.reject(_error);
				}
			}
		}

		return Promise.reject(err);
	}
);

export default instance;
