import { Badge, Box, Stack, Typography } from '@mui/material';
import { QueryClient } from '@tanstack/react-query';
import { useAlert } from 'context/alertContext';
import { NotificationMarkAsRead } from 'framework/users/mutation/update-notification';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { INotification } from 'types/notification';

interface Props {
  notification: INotification;
}


interface Props {
  notification: INotification;
}

const NotificationCard: React.FC<Props> = ({ notification }) => {
  const { setAlert } = useAlert();
  const { t } = useTranslation();
  const { mutateAsync: notificationMarkAsRead } = NotificationMarkAsRead();
  // const { mutateAsync: deleteNotification } = DeleteNotification();
  const queryClient = new QueryClient();

  const redirect = () => window.location.href = notification.RedirectionURL.replace(/\/\//g, "/").replace('http:/', 'http://');


  const markRead = async (Id: number) => {
    try {
      await notificationMarkAsRead({ Id });
      queryClient.invalidateQueries([`notifications`]);
      redirect();
    } catch (err: Error | any) {
      setAlert({
        open: true,
        message: err.Message || t('error.serverError'),
        type: 'error'
      });
    }
  };
 
  // const handleDeleteNotification = async (Id: number) => {
  //   try {
  //     await deleteNotification({ Id });
  //     queryClient.invalidateQueries([`notifications`]);
  //   } catch (err: Error | any) {
  //     setAlert({
  //       open: true,
  //       message: err.Message || t('error.serverError'),
  //       type: 'error'
  //     });
  //   }
  // };


  return (
    <Stack alignItems={'start'}
      useFlexGap sx={{
        cursor: !notification.IsRead ? 'pointer' : 'default',
        "&:hover": {
          boxShadow: !notification.IsRead ? '0px 0px 10px 0px rgba(0,0,0,0.1)' : null,
        },
      }}
      onClick={() => !notification.IsRead ? markRead(notification.Id) : redirect()}
      borderBottom={1}
      borderColor={'divider'}
      px={2}
      pb={1}
    >
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
        <Badge variant={'dot'} color={'error'} invisible={notification.IsRead} />


        <Box component={'img'} src={
          notification.ImagePath || "https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d50?s=50"
        } sx={{
          width: 60,
          height: 60,
          borderRadius: '50%',
          objectFit: 'contain',
          flexShrink: 0,
          aspectRatio: '1/1',
        }} />
        <Stack spacing={1} useFlexGap>
          <Typography variant={'body2'} fontSize={15} fontWeight={400} color={'black'}
            sx={{
              lineClamp: 2,
              boxOrient: 'vertical',
              maxWidth: 350,
            }}
          >{notification.Message}</Typography>
          <Typography variant={'body2'} color={'GrayText'}>{
            new Date(notification.CreatedOn).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })
            + ' ' + new Date(notification.CreatedOn).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
          }</Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default NotificationCard;

