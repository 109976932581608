import { useMutation } from '@tanstack/react-query';
import { Endpoints } from 'enums/endpoints';
import http from 'utils/http';

export const useUserActivate = () => {
	return useMutation<any, Error, string>(async (token) => {
		const { data } = await http.post(Endpoints.USER_ACTIVATE + '?token=' + token);

		return data;
	});
};
