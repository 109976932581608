import { Control } from 'react-hook-form';

import FormFields from 'components/UI/FormFields';
import { IFormField } from 'types/formFields';
import { InputTypes } from 'enums/input-types';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

interface Props {
	control: Control;
	errors: any;
	watch: any;
	setValue: any;
	register: any;
}

const BasicFields: React.FC<Props> = ({ control, errors, watch, setValue, register }) => {
	const fields: IFormField[] = [
		{
			name: 'email',
			placeholder: 'Email',
			type: InputTypes.TEXT
		},
		{
			name: 'password',
			placeholder: 'Password',
			type: InputTypes.PASSWORD
		},
		{
			name: 'terms',
			type: InputTypes.CHECKBOX,
			label: (
				<Typography>
					I agree to Scofolio’s <Link to={'/terms-of-service'}>Terms of Service</Link>, <Link to={'/privacy-policy'}>Privacy Policy</Link>.
				</Typography>
			)
		}
	];

	return (
		<>
			{fields.map((field: any) => (
				<FormFields {...field} key={field.name} control={control} errors={errors} watch={watch} setValue={setValue} register={register} />
			))}
		</>
	);
};

export default BasicFields;
