import { Stack, Typography } from '@mui/material';
import FormFields from 'components/UI/FormFields';
import { InputTypes } from 'enums/input-types';
import { Control, FieldErrors, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { IFormField } from 'types/formFields';

type Props = {
	control: Control<any, any>;
	register: UseFormRegister<any>;
	errors: FieldErrors<any>;
	watch: UseFormWatch<any>;
	setValue: UseFormSetValue<any>;
};

const ForgetPasswordFields = ({ control, errors, watch, setValue, register }: Props) => {
	const fields: IFormField[] = [
		{
			name: 'email',
			placeholder: 'email',
			type: InputTypes.TEXT
		}
	];

	return (
		<>
			<Stack alignItems={'center'}>
				<Typography gutterBottom variant="subtitle1" textAlign={'center'}>
					Forgot your password?
				</Typography>
				<Typography paragraph variant="subtitle2" textAlign={'center'} color={'text.secondary'}>
					Enter your email
				</Typography>
			</Stack>
			{fields.map((field: any) => (
				<FormFields {...field} key={field.name} control={control} errors={errors} watch={watch} setValue={setValue} register={register} />
			))}


		</>
	);
};

export default ForgetPasswordFields;
