import { Box, Grid, Stack, Typography } from "@mui/material";
import useAuthStore from "store/auth";
import { Profile, ProfileTopic } from "types/profile";
import CheckIcon from '@mui/icons-material/Check';

const TopicsListForShowcase: React.FC = () => {
	const session: Profile = useAuthStore((state) => state.session);
	const topics: ProfileTopic[] = session.Topics.filter((item) => item.IsSelected) || [];



	if (!topics) return null;
	return (

		<Stack direction={'column'} spacing={1} >
			{topics.map((topic, i: number) => (
				<Grid container direction={'row'} justifyContent={'space-between'} key={i} alignItems={'center'} spacing={1}>
					<Grid container justifyContent={'start'} alignItems={'center'} maxWidth={'90%'}>
						<Box sx={{
							minWidth: 16, minHeight: 16, borderRadius: '50%', bgcolor: topic.TopicFontColor,
							maxWidth: '30%',
						}} />
						<Typography sx={{
							WebkitLineClamp: 2,
							WebkitBoxOrient: 'vertical',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							display: '-webkit-box',
							maxWidth: '70%',
							mx: 1,
							fontWeight: 500,
							color: 'text.secondary',
						}}
							variant={'body2'}
						>{topic.Name}</Typography>
					</Grid>
					<CheckIcon color={'success'} sx={{ maxWidth: '10%' }} />

				</Grid>
			))}
		</Stack>
	);
};

export default TopicsListForShowcase;
