import { Box, Skeleton } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import HtmlContent from 'components/UI/HtmlContent';
import { getTermsAndConditions } from 'framework/privacyAndPolicy/query/fetching';
import React, { useCallback } from 'react';

const TermsOfService: React.FC = () => {
	const [loading, setLoading] = React.useState(false);
	const [termsOfService, setTermsOfService] = React.useState<string | null>(null);

	const queryClient = useQueryClient();

	const fetchTermsOfService = useCallback(async () => {
		try {
			setLoading(true);
			const response: any = await queryClient.fetchQuery(['termsOfService'], getTermsAndConditions);
			setTermsOfService(response?.Data);
			setLoading(false);
		} catch (err: any) {
			setLoading(false);

		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		fetchTermsOfService();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	if (loading) return <Skeleton variant="rectangular" width="100%" height={118} animation="wave" />;
	if (!termsOfService) return null;

	return (
		<>
			<Box sx={{ width: '100%' }}>
				<HtmlContent content={termsOfService} />
			</Box>
		</>
	);
};

export default TermsOfService;
