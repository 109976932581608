import { Box, IconButton } from '@mui/material';
import useAuthStore from 'store/auth';
import getInitial from 'utils/initial';

interface Props {
	name?: string;
	size?: 'SMALL' | 'MEDIUM' | 'LARGE';
	imageWidth?: number;
	imageHeight?: number;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	noImage?: boolean;
}

const ProfileAvatar: React.FC<Props> = ({ name, size = 'MEDIUM', onClick, imageWidth, imageHeight, noImage }) => {
	const session = useAuthStore((state) => state.session);
	return (
		<IconButton
			color="inherit"
			aria-label="show more"
			aria-haspopup="true"
			onClick={onClick}
			sx={{
				width: size === 'SMALL' ? 32 : size === 'MEDIUM' ? 48 : 120,
				height: size === 'SMALL' ? 32 : size === 'MEDIUM' ? 48 : 120,
				bgcolor: session.ProfileImagePath && !noImage ? 'transparent' : 'primary.main',
				color: 'common.white',
				fontSize: size === 'SMALL' ? '1.2rem' : size === 'MEDIUM' ? '1.5rem' : '4rem',
				p: 0,
				'&:hover': { bgcolor: 'primary.main', color: 'common.white' }
			}}>
			{session && session.ProfileImagePath && !noImage ? <>
				<Box component={'img'} src={session.ProfileImagePath.replace('\\Uploads\\', 'https://devapi.scofolio.com/Uploads/')} alt={session.Name}
					width={imageWidth || 50}
					height={imageHeight || 50}
					sx={{
						borderRadius: '50%',
						objectFit: 'fill',
						objectPosition: 'center',
						verticalAlign: 'middle'
					}} />
			</> : getInitial(name || session.FirstName + ' ' + session.LastName)}
		</IconButton>
	);
};

export default ProfileAvatar;
