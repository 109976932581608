import { useQuery } from '@tanstack/react-query';
import { Endpoints } from 'enums/endpoints';
import { TQueryOptions } from 'types/query-options';
import { INotificationResponse } from 'types/response';
import http from 'utils/http';

export const listing = async ({ queryKey }: any) => {
	const { data } = await http.get(Endpoints.NOTIFICATION);

	return data;
};

export const useNotificationQuery = (options: TQueryOptions) => {
	return useQuery<INotificationResponse, Error>([Endpoints.NOTIFICATION, options], listing);
};
