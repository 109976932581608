import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { CacheProvider, EmotionCache, ThemeProvider } from '@emotion/react';
import { createTheme, CssBaseline, GlobalStyles, responsiveFontSizes } from '@mui/material';
import lightThemeOptions from './theme/options';
import createEmotionCache from './theme/emotion-cache';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { TestFiltersProvider } from 'context/test-filters';
import { AlertProvider } from 'context/alertContext';
import { I18nextProvider } from "react-i18next";
import i18n from "i18n";
import "assets/styles/fonts.css";


const queryClient = new QueryClient();
let i18nextLng = localStorage.getItem('i18nextLng') || 'en';

let lightTheme = createTheme(lightThemeOptions(i18nextLng));


lightTheme = responsiveFontSizes(lightTheme);
const emotionCache: EmotionCache = createEmotionCache();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
	<>
		<QueryClientProvider client={queryClient}>
			<CacheProvider value={emotionCache}>
				<I18nextProvider i18n={i18n}>
					<ThemeProvider theme={lightTheme}>
						<AlertProvider>
							<CssBaseline />
							<GlobalStyles
								styles={{
									svg: {
										display: 'block',
										width: 'auto',
										height: 'auto',
										maxWidth: '100%'
									},
									'.html-content': {
										paddingLeft: 8,
										paddingRight: 8
									},
									'.html-content p': {
										backgroundColor: '#FFF9C4',
										padding: 8,
										margin: 0,
										marginBottom: 8
									},
									'.reservation-comment p': {
										margin: 0
									},
									'.MuiSnackbar-root': {
										right: '24px !important',
										left: '24px !important'
									},
									'.MuiButton-contained': {
										color: 'white'
									},
									'.MuiPaper-root-MuiSnackbarContent-root': {
										width: '100%'
									},
									'.MuiDrawer-paper': {
										borderRight: 'none !important'
									},
									'.MuiAccordionSummary-root .MuiAccordionSummary-content': {
										margin: '12px 8px !important'
									},
									'.MuiAccordionSummary-root .MuiAccordionSummary-content.Mui-expanded': {
										margin: '12px 8px !important'
									},
									'.MuiAccordionSummary-root.Mui-expanded': {
										minHeight: '0 !important'
									},
									'.MuiAccordionSummary-root.Mui-expanded .MuiTypography-root': {
										marginLeft: '0 !important'
									},
									'.tox-tinymce': {
										borderWidth: '1px !important',
										borderRadius: '0px !important'
									},
									'#editor textarea': {
										display: 'none'
									},
									'.sketch-picker ': {
										borderRadius: '0px !important',
										width: 'calc(100% - 20px) !important',
										boxShadow: 'none !important'
									},
									'.recharts-pie-labels': {
										display: 'none'
									},
									"& .recharts-pie-labels": {
										display: "none",
									},
									'&::-webkit-scrollbar, & *::-webkit-scrollbar': {
										backgroundColor: '#eee',
										maxWidth: 5,
										maxHeight: 10
									},
									'&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
										backgroundColor: 'rgba(0,0,0,.1)',
										minHeight: 10
									},
									'&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus': {
										backgroundColor: '#717171'
									},
									'&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active': {
										backgroundColor: '#717171'
									},
									'&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover': {
										backgroundColor: '#717171'
									},
									'&::-webkit-scrollbar-track': {
										boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
										webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
									},


								}}
							/>
							<TestFiltersProvider>
								<Router>
									<App />
								</Router>
							</TestFiltersProvider>
						</AlertProvider>
					</ThemeProvider>
				</I18nextProvider>
			</CacheProvider>
		</QueryClientProvider>
	</>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
