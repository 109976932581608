import { Children, JSXElementConstructor, ReactElement, cloneElement } from 'react';
import ContentLoader from '../ContentLoader';
import RequestError from '../RequestError';
import { TQueryOptions } from 'types/query-options';

interface Props {
	resourceName: string;
	useListingQuery: any;
	queryOptions?: TQueryOptions;
	children: ReactElement<ChildProps, string | JSXElementConstructor<any>> | readonly ReactElement<ChildProps, string | JSXElementConstructor<any>>[];
}

export interface ChildProps {
	data?: any;
	resourceName?: string;
}

const Listing: React.FC<Props> = ({ resourceName, useListingQuery, queryOptions = {}, children }) => {
	const { isLoading, error, data } = useListingQuery(queryOptions);

	if (isLoading) return <ContentLoader />;
	if (error) return <RequestError message={error.message} />;
	if (data.length === 0) return <RequestError message={'There is no ' + resourceName + ' to list'} />;

	const childrenWithProps = Children.map(children, (child: ReactElement<ChildProps>) => {
		return cloneElement(child, { data, resourceName });
	});

	return <>{childrenWithProps}</>;
};

export default Listing;
