import { Route, Routes as ReactRoutes, useLocation } from 'react-router-dom';

import PublicRoutes from 'pages/routes/public';
import PrivateRoutes from 'pages/routes/private';
import { Routes } from 'enums/routes';
import { useCallback, useEffect } from 'react';
import useApp from 'hooks/app';
import useLoading from 'hooks/loading';
import ActionLoader from 'components/UI/ActionLoader';
import { QueryClient } from '@tanstack/react-query';
import { Endpoints } from 'enums/endpoints';

import { listing as profileListing } from 'framework/profile/query/listing';
import { LocalStorage } from 'enums/local-storage';
import useAuthStore from 'store/auth';
import { IUpdateProfileResponse } from 'types/auth-form';
import { useTranslation } from 'react-i18next';
import { minimizeLangText } from 'utils/utils';
import { Pages } from 'enums/pages';

const queryClient = new QueryClient();

const App: React.FC = () => {
	const setSession = useAuthStore((state) => state.setSession);
	const { pathname, search } = useLocation();
	const { push } = useApp();
	const { loading, setLoading } = useLoading();
	const { i18n } = useTranslation();

	const redirectToLogin = (pathname: string = ""): void => {
		setSession(null);
		setLoading(false);
		localStorage.removeItem(LocalStorage.ACCESS_TOKEN);
		localStorage.removeItem(LocalStorage.REFRESH_TOKEN);
		if (pathname && !pathname.includes('reset-password')) sessionStorage.setItem('redirect', pathname);
		if (pathname.includes(Routes.DASHBOARD)) push(Routes.ROOT);
		else push(search.length === 0 ? pathname : pathname + search);
	};



	const initApp = useCallback(async () => {
		try {
			const response: IUpdateProfileResponse = await queryClient.fetchQuery([Endpoints.PROFILE, {}], profileListing);
			setSession(response.Data);
			 if (response.Data.ErrorLog === 10) {
				localStorage.setItem(LocalStorage.PROFILE, JSON.stringify(response.Data));
				setLoading(false);
				return push(Routes.ROOT + Pages.FINISH_REGISTRATION);
			}
			else if (response.Data.ErrorLog === 11) {
				localStorage.setItem(LocalStorage.PROFILE, JSON.stringify(response.Data));
				setLoading(false);
				return push(Routes.ROOT + Pages.INTERESTS);
			}
			setLoading(false);
			let lang = response.Data.languages.find((lang) => lang.Selected)?.Name || 'English';
			lang = minimizeLangText(lang);
			i18n.changeLanguage(lang);
			if (!pathname || pathname === "/") push(Routes.DASHBOARD + Pages.SHOWCASES);
			else push(search.length === 0 ? pathname : pathname + search);
		} catch (err: Error | any) {
			redirectToLogin(pathname);
		}

		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const accessToken: string | null = localStorage.getItem(LocalStorage.ACCESS_TOKEN);
		const refreshToken: string | null = localStorage.getItem(LocalStorage.REFRESH_TOKEN);

		if (accessToken && refreshToken) initApp();
		else redirectToLogin(pathname);

		// eslint-disable-next-line
	}, []);

	if (loading) return <ActionLoader position="fixed" />;

	return (
		<ReactRoutes>
			<Route path={Routes.ROOT + '*'} element={<PublicRoutes />} />
			<Route path={Routes.DASHBOARD + '*'} element={<PrivateRoutes key={pathname} />} />
		</ReactRoutes>
	);
};

export default App;
