import { useMutation } from '@tanstack/react-query';
import { Endpoints } from 'enums/endpoints';
import { FirstLastNameUpdateInput } from 'types/auth-form';
import http from 'utils/http';

export const useUpdateFirstLastName = () => {
	return useMutation<any, Error, FirstLastNameUpdateInput>(async (input) => {
		const urlParams: string = '?FirstName=' + input.FirstName + '&LastName=' + input.LastName + '&Email=' + input.Email + '&Password=' + input.Password;

		const { data } = await http.post(Endpoints.USER_UPDATE_FIRST_LAST_NAME + urlParams);

		return data;
	});
};
