import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
import useAuthStore from 'store/auth';
import useApp from 'hooks/app';
import { Routes } from 'enums/routes';
import { Pages } from 'enums/pages';
import { useTranslation } from 'react-i18next';
import { Languages } from 'enums/languages';

type Props = {
	element: EventTarget & HTMLElement | null;
	open: boolean;
	handleClose: () => void;
};

const ProfileMenu = ({ element, open, handleClose }: Props) => {
	const setSession = useAuthStore((state) => state.setSession);
	const { t, i18n } = useTranslation();
	const { push } = useApp();

	const handleAdminProfile = async () => {
		push(`${Routes.DASHBOARD + Pages.SETTINGS}`);
		handleClose();
	};


	const handleChangeDirection = (lang: string) => {
		if (lang === Languages.ENGLISH_FULL_NAME || lang === Languages.ENGLISH) {
			document.documentElement.setAttribute('lang', 'en');
			document.documentElement.setAttribute('dir', 'ltr');
		} else {
			document.documentElement.setAttribute('lang', 'ar');
			document.documentElement.setAttribute('dir', 'rtl');
		}

	}

	const handleLogout = () => {
		handleClose();
		localStorage.clear();
		setSession(null);
		localStorage.clear();
		i18n.changeLanguage(Languages.ENGLISH);
		handleChangeDirection(Languages.ENGLISH)
		push(Routes.ROOT);
	};



	return (
		<Menu id={'profile-menu'} anchorEl={element} open={open} onClose={handleClose} sx={{
			mt: 1,
			'& .MuiPaper-root': {
				backgroundColor: 'white',
				borderRadius: '16px',
				boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
				'.Mui-selected': {
					backgroundColor: '#00B5A2 !important',
					color: 'white !important',
					borderRadius: '8px'
				}
			},
		}}>
			<MenuItem onClick={handleAdminProfile}>
				<ListItemIcon>
					<PersonIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>{t('header.profile')}</ListItemText>
			</MenuItem>
			<MenuItem onClick={handleLogout}>
				<ListItemIcon>
					<LogoutIcon fontSize="small" />
				</ListItemIcon>
				<ListItemText>{t('header.logout')}</ListItemText>
			</MenuItem>
		</Menu>
	);
};

export default ProfileMenu;
