import { Box, Skeleton } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import HtmlContent from 'components/UI/HtmlContent';
import { getHelp } from 'framework/privacyAndPolicy/query/fetching';
import React, { useCallback } from 'react';

const Help: React.FC = () => {

	const [loading, setLoading] = React.useState(false);
	const [help, setHelp] = React.useState<string | null>(null);

	const queryClient = useQueryClient();

	const fetchPrivacyAndPolicy = useCallback(async () => {
		try {
			setLoading(true);
			const response: any = await queryClient.fetchQuery(['help'], getHelp);
			setHelp(response?.Data?.Description!);
			setLoading(false);
		} catch (err: any) {
			setLoading(false);

		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		fetchPrivacyAndPolicy();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	if (loading) return <Skeleton variant="rectangular" width="100%" height={118} animation="wave" />;
	if (!help) return null;

	return (
		<>
			<Box sx={{
				width: '100%',
				overflowY: 'auto',
				padding: '0 20px',
			}}>
				<HtmlContent content={help} />
			</Box>
		</>
	);
};

export default Help;
