import Auth from 'components/auth';
import { Route, Routes, useLocation } from 'react-router-dom';


const PublicRoutes: React.FC = () => {
	const { pathname } = useLocation();
	return (
		<Routes>
			<Route path="/" element={<Auth key={pathname} />} />
			<Route path="/activate-account/:activateToken" element={<Auth />} />
			<Route path="/reset-password/:resetToken" element={<Auth />} />
			<Route path=":slug/*" element={<Auth key={pathname} />} />
		</Routes>
	);
};

export default PublicRoutes;
