import { Box, Checkbox, Grid, Stack, Typography } from '@mui/material';
import { ITopic } from 'types/topic';

type Props = {
	topic: ITopic;
	isSelected: boolean;
	handleSelect: (id: number) => void;
};

const TopicItemForShowcase = ({ topic, isSelected, handleSelect }: Props) => {
	return (
		<Stack direction={'row'} sx={{
			cursor: 'pointer',
			'&:hover': {
				bgcolor: 'grey.200'
			},
			mb: 1
		}} justifyContent={'space-between'} alignItems={'center'} spacing={1}>
			<Grid container justifyContent={'start'} alignItems={'center'} maxWidth={'90%'}>
				<Box sx={{
					minWidth: 16, minHeight: 16, borderRadius: '50%', bgcolor: topic.TopicFontColor,
					maxWidth: '30%',
				}} />
				<Typography sx={{
					WebkitLineClamp: 2,
					WebkitBoxOrient: 'vertical',
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					display: '-webkit-box',
					maxWidth: '70%',
					mx: 1,
					fontWeight: 500,
					color: 'text.secondary',
				}}
					variant={'body2'}
				>{topic.Name}</Typography>
			</Grid>
			<Checkbox defaultChecked={isSelected} onClick={() => handleSelect(topic.Id)} />
		</Stack>
		// <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} sx={{
		// 	cursor: 'pointer',
		// 	'&:hover': {
		// 		bgcolor: 'grey.200'
		// 	},
		// 	mb: 1
		// }}>
		// 	<Stack direction={'row'} alignItems={'center'} spacing={1}>
		// 		<Box sx={{ width: 16, height: 16, borderRadius: '50%', bgcolor: topic.TopicColor }} />
		// 		<Typography>{topic.Name}</Typography>
		// 	</Stack>
		// 	<Checkbox defaultChecked={isSelected} onClick={() => handleSelect(topic.Id)} />
		// </Stack>

	);
};

export default TopicItemForShowcase;
