export enum Modals {
	EMPTY = '',
	PAGES_MODAL = 'pages-modal',
	PAGES_ITEM_MODAL = 'pages-item-modal',
	SECTIONS_MODAL = 'sections-modal',
	HEADER_DIALOG = 'header-dialog',
	LOGO_DIALOG = 'logo-dialog',
	BUTTON_DIALOG = 'button-dialog',
	MENU_MODAL = 'menu-modal',
	MENU_ITEM_MODAL = 'menu-item-modal'
}
