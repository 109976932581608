export type TPopup = {
	open: boolean;
	title: string;
	content: null | React.ReactNode;
};

export const emptyPopup: TPopup = {
	open: false,
	title: '',
	content: null
};
