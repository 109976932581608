import { useEffect, useState } from 'react';
import { Box, IconButton, Menu, Pagination, Stack, Typography } from '@mui/material';
import { INotification } from 'types/notification';
import NotificationCard from './notificationCard';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { Languages } from 'enums/languages';
import { NotificationMarkMultipleAsRead } from 'framework/users/mutation/update-notification';
import { useAlert } from 'context/alertContext';
import { useQueryClient } from '@tanstack/react-query';


type Props = {
	notifications?: INotification[];
	element: EventTarget & HTMLElement;
	open: boolean;
	handleClose: () => void;
};

const NotificationsMenu = ({ notifications, element, open, handleClose }: Props) => {
	const { t, i18n } = useTranslation();
	const [page, setPage] = useState(1);
	const pageSize = 5;
	const { mutateAsync: notificationMarkMultipleAsRead } = NotificationMarkMultipleAsRead();
	const { setAlert } = useAlert();
	let queryClient = useQueryClient();

	const markReadMultiple = async (Ids: number[]) => {
		try {
			let notificationsIds = Ids.map((Id) => Id.toString()).join(',');
			await notificationMarkMultipleAsRead(notificationsIds);
			queryClient.invalidateQueries([`notifications`]);
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err.Message || t('error.serverError'),
				type: 'error'
			});
		}
	};

	useEffect(() => {
		if (notifications && notifications.length > 0) {
			let filteredNotificationsByPage = notifications.slice((page - 1) * pageSize, page * pageSize);
			markReadMultiple(filteredNotificationsByPage?.filter((notification) => !notification.IsRead).map((notification) => notification.Id) || []);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page]);

	const renderContent = () => {
		if (!notifications || notifications.length === 0) {
			return (
				<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} p={3} spacing={3}>
					<IconButton size="small" onClick={handleClose}
						sx={{
							display: { xs: 'block', md: 'none' }
						}}
					>
						<Close />
					</IconButton>
					<Box sx={{
						width: '100%',
						textAlign: 'center',
					}}>
						<Typography>{t('header.no_notifications')}</Typography>
					</Box>
				</Stack>
			);
		} else {
			const startIndex = (page - 1) * pageSize;
			const endIndex = startIndex + pageSize;
			const paginatedNotifications = notifications.slice(startIndex, endIndex);

			return (
				<Stack p={3} spacing={3} useFlexGap>
					<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
						<IconButton size="small" onClick={handleClose}
							sx={{
								display: { xs: 'block', md: 'none' }
							}}
						>
							<Close />
						</IconButton>
						<Typography fontWeight={700}>{t('header.notifications')}</Typography>
						{/* <Button size="small">{t('header.viewAll')}</Button> */}
					</Stack>
					<Stack spacing={2} useFlexGap>
						{paginatedNotifications.map((notification: INotification) => (
							<NotificationCard key={notification.Id} notification={notification} />
						))}
					</Stack>
					<Pagination
						sx={{
							// make pagination arrow icons rotate 90 degrees
							'& .MuiPaginationItem-root': {
								// arrows are rotated 90 degrees
								'& .MuiSvgIcon-root': {
									transform: i18n.language === Languages.ARABIC ? 'rotate(180deg)' : 'rotate(0deg)',
								},

							},
						}}
						count={Math.ceil(notifications.length / pageSize)} page={page} onChange={(event, value) => setPage(value)} />
				</Stack>
			);
		}
	};

	return (
		<Menu id={'notifications-menu'} anchorEl={element} open={open} onClose={handleClose}
			sx={{
				mt: 1,

				justifyContent: 'flex-start',
				'& .MuiPaper-root': {
					backgroundColor: 'white',
					borderRadius: '16px',
					boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.08)',
					// mn mobile view in full screen height
					minHeight: {
						xs: '100%',
						md: 'unset'
					},
					width: {
						xs: '100%',
						md: 'unset'
					},

					'.Mui-selected': {
						backgroundColor: '#00B5A2 !important',
						color: 'white !important',
						borderRadius: '8px'
					},
				},
			}} keepMounted>
			{renderContent()}
		</Menu >
	);
};


export default NotificationsMenu;
