import { useMutation } from '@tanstack/react-query';
import { Endpoints } from 'enums/endpoints';
import { IChangePasswordInput } from 'types/auth-form';
import http from 'utils/http';

export const useResetPassword = () => {
	return useMutation<any, Error, IChangePasswordInput>(async (input) => {
		const { data } = await http.post(Endpoints.USER_RESET_PASSWORD, input);

		return data;
	});
};
