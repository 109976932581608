import { AuthCreateInput } from 'types/auth-form';
import { UserSettings } from 'types/profile';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type State = {
	email: string;
	session: any;
	settings: UserSettings;
	loginCache: AuthCreateInput | null;
};

type Actions = {
	setEmail: (payload: string) => void;
	setSession: (payload: any) => void;
	setSettings: (payload: UserSettings) => void;
	setLoginCache: (payload: AuthCreateInput | null) => void;
};

const useAuthStore = create(
	persist<State & Actions>(
		(set) => ({
			session: null,
			email: '',
			settings: {} as UserSettings,
			loginCache: null,
			setEmail: (payload: string) => set((state) => ({ ...state, email: payload })),
			setSession: (payload: any) => set((state) => ({ ...state, session: payload })),
			setSettings: (payload: UserSettings) => set((state) => ({ ...state, settings: payload })),
			setLoginCache: (payload: AuthCreateInput | null) => set((state) => ({ ...state, loginCache: payload })),
		}),
		{ name: 'auth-store' }
	)
);

export default useAuthStore;
