interface Props {
	active?: boolean;
}

const SurveySVG: React.FC<Props> = ({ active }) => {
	return (
		<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M2.0001 11H8.0001C8.26531 11 8.51967 10.8946 8.7072 10.7071C8.89474 10.5196 9.0001 10.2652 9.0001 10V4C9.0001 3.73478 8.89474 3.48043 8.7072 3.29289C8.51967 3.10536 8.26531 3 8.0001 3H2.0001C1.73488 3 1.48053 3.10536 1.29299 3.29289C1.10545 3.48043 1.0001 3.73478 1.0001 4V10C1.0001 10.2652 1.10545 10.5196 1.29299 10.7071C1.48053 10.8946 1.73488 11 2.0001 11ZM3.0001 5H7.0001V9H3.0001V5ZM23.0001 7C23.0001 7.26522 22.8947 7.51957 22.7072 7.70711C22.5197 7.89464 22.2653 8 22.0001 8H12.0001C11.7349 8 11.4805 7.89464 11.293 7.70711C11.1055 7.51957 11.0001 7.26522 11.0001 7C11.0001 6.73478 11.1055 6.48043 11.293 6.29289C11.4805 6.10536 11.7349 6 12.0001 6H22.0001C22.2653 6 22.5197 6.10536 22.7072 6.29289C22.8947 6.48043 23.0001 6.73478 23.0001 7ZM23.0001 17C23.0001 17.2652 22.8947 17.5196 22.7072 17.7071C22.5197 17.8946 22.2653 18 22.0001 18H12.0001C11.7349 18 11.4805 17.8946 11.293 17.7071C11.1055 17.5196 11.0001 17.2652 11.0001 17C11.0001 16.7348 11.1055 16.4804 11.293 16.2929C11.4805 16.1054 11.7349 16 12.0001 16H22.0001C22.2653 16 22.5197 16.1054 22.7072 16.2929C22.8947 16.4804 23.0001 16.7348 23.0001 17ZM3.2351 19.7L1.2811 17.673C1.09664 17.4823 0.995487 17.2262 0.999894 16.9609C1.0043 16.6956 1.11391 16.443 1.3046 16.2585C1.49529 16.074 1.75144 15.9729 2.01671 15.9773C2.28198 15.9817 2.53464 16.0913 2.7191 16.282L3.9711 17.582L7.3001 14.289C7.39104 14.1866 7.50206 14.104 7.62626 14.0462C7.75045 13.9885 7.88518 13.9569 8.0221 13.9534C8.15901 13.9499 8.29519 13.9745 8.42219 14.0258C8.54919 14.0771 8.6643 14.1539 8.76039 14.2515C8.85647 14.3491 8.93149 14.4654 8.98078 14.5931C9.03007 14.7209 9.05259 14.8575 9.04694 14.9943C9.04128 15.1312 9.00758 15.2654 8.94792 15.3887C8.88826 15.5119 8.80391 15.6217 8.7001 15.711L4.6541 19.711C4.46767 19.8954 4.21629 19.9992 3.9541 20H3.9421C3.8103 19.9991 3.67998 19.9721 3.55865 19.9206C3.43731 19.8691 3.32736 19.7941 3.2351 19.7Z"
				fill={active ? '#000000' : '#62656A'}
			/>
		</svg>
	);
};

export default SurveySVG;
