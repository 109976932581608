import * as yup from 'yup';

export const AUTH_LOGIN = yup.object().shape({
	email: yup.string().required('You need to enter an email. This is a mandatory field.').email('Please enter a valid email address.'),
	password: yup.string().required('You need to enter a password. This is a mandatory field.').min(8, 'Your password should have at least 8 characters.')
});

export const AUTH_REGISTER = yup.object().shape({
	email: yup.string().required('You need to enter an email. This is a mandatory field.').email('Please enter a valid email address.'),
	password: yup.string().required('You need to enter a password. This is a mandatory field.').min(8, 'Your password should have at least 8 characters.'),
	terms: yup.boolean().required().oneOf([true], 'You need to consent to the terms.')
});

export const FINISH_REGISTRATION = yup.object().shape({
	first_name: yup.string().required('You need to enter your first name. This is a mandatory field.'),
	last_name: yup.string().required('You need to enter your last name. This is a mandatory field.'),
	email: yup.string().required('You need to enter an email. This is a mandatory field.').email('Please enter a valid email address.'),
});
export const FINISH_REGISTRATION_WITH_PASSWORD = yup.object().shape({
	first_name: yup.string().required('You need to enter your first name. This is a mandatory field.'),
	last_name: yup.string().required('You need to enter your last name. This is a mandatory field.'),
	email: yup.string().required('You need to enter an email. This is a mandatory field.').email('Please enter a valid email address.'),
	NewPassword: yup.string().required('You need to enter a password. This is a mandatory field.').min(8, 'Your password should have at least 8 characters.'),
	ConfirmPassword: yup.string().oneOf([yup.ref('NewPassword')], 'Passwords mismatch').min(8, 'Your password should have at least 8 characters.')
});

export const RESET_PASSWORD = yup.object().shape({
	NewPassword: yup.string().required('You need to enter a password. This is a mandatory field.').min(8, 'Your password should have at least 8 characters.'),
	ConfirmPassword: yup.string().oneOf([yup.ref('NewPassword')], 'Passwords mismatch').min(8, 'Your password should have at least 8 characters.')
});

export const DEFAULT_VALIDATION = yup.object();
