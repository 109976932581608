interface Props {
	active?: boolean;
}

const DashboardSVG: React.FC<Props> = ({ active }) => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M6 16C6 15.4477 6.44772 15 7 15H10C10.5523 15 11 15.4477 11 16V19C11 19.5523 10.5523 20 10 20H7C6.44772 20 6 19.5523 6 19V16Z"
				stroke={active ? '#000000' : '#62656A'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M4 5C4 4.44772 4.44772 4 5 4H10C10.5523 4 11 4.44772 11 5V10C11 10.5523 10.5523 11 10 11H5C4.44772 11 4 10.5523 4 10V5Z"
				stroke={active ? '#000000' : '#62656A'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15 16C15 15.4477 15.4477 15 16 15H19C19.5523 15 20 15.4477 20 16V19C20 19.5523 19.5523 20 19 20H16C15.4477 20 15 19.5523 15 19V16Z"
				stroke={active ? '#000000' : '#62656A'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M15 7C15 6.44772 15.4477 6 16 6H19C19.5523 6 20 6.44772 20 7V10C20 10.5523 19.5523 11 19 11H16C15.4477 11 15 10.5523 15 10V7Z"
				stroke={active ? '#000000' : '#62656A'}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default DashboardSVG;
