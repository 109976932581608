import { SubmitHandler, useForm } from "react-hook-form";
import { Grid, Stack } from "@mui/material";

import FormFields from "../FormFields";
import { useEffect } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { IFormField } from "types/formFields";
import MuiButton from "components/UI/MuiButton";

interface Props {
  fields: IFormField[];
  buttonText?: string;
  defaultValues?: any;
  dir?: "rtl" | "ltr" | undefined;
  cb?: (data: any) => void;
  onChange?: (name: string, value: any) => void;
  validations?: any;
  reset?: Boolean,
}

const validation = (validations: any) => {
  if (validations) return Yup.object().shape(validations);
  else return Yup.object().shape({});
}

const Form: React.FC<Props> = ({ fields, buttonText, defaultValues, dir, cb, onChange, validations, reset }) => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset: _reset,
    register,
    formState: { errors, isSubmitting, isValid },
  } = useForm<any>({ defaultValues, mode: 'onChange', resolver: yupResolver(validation(validations) as any) });

  const onSubmit: SubmitHandler<any> = async (data: any) => cb && cb(data);


  useEffect(() => {

    if (reset) {
      _reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  return (
    <>
      <form dir={dir} >
        {fields.length > 0 &&
          <Stack direction={'column'} spacing={2} >
            {fields.map((field: any) => (
              <FormFields
                {...field}
                control={control}
                errors={errors}
                watch={watch}
                setValue={setValue}
                register={register}
                onChange={(e: any) => {
                  onChange && onChange(field.name, !e ? null : e.target?.value! || e?.value! || e!);
                }}
              />
            ))}
          </Stack>
        }
        {buttonText && <Grid container justifyContent="center" sx={{ py: 4 }}>
          <MuiButton
            size="large"
            variant="contained"
            type="submit"
            disabled={isSubmitting || Object.keys(errors).length > 0 || !isValid}
            sx={{ minWidth: 184 }}
            onClick={handleSubmit(onSubmit)}
          >
            {buttonText}
          </MuiButton>
        </Grid>}
      </form>
    </>
  );
};

export default Form;
