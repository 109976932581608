import { Box, Button, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { useState } from 'react';
import usePopupStore from 'store/popup';
import { emptyPopup } from 'types/popup';
import useAuthStore from 'store/auth';
import { Profile, ProfileTopic } from 'types/profile';
import TopicItemForShowcase from './partials/topic-item_for_showcase';
import { useTranslation } from 'react-i18next';
import useShowcaseStore from 'store/showcase';
import { useUpdateUserAccount } from 'framework/auth/mutation/updateUserAccount';
import { useAlert } from 'context/alertContext';
import { IUpdateProfileResponse } from 'types/auth-form';


const AddTopicPopupForShowcase = () => {
	const session: Profile = useAuthStore((state) => state.session);
	const setSession = useAuthStore((state) => state.setSession);
	let setFilter = useShowcaseStore((state) => state.setFilter);
	let selectedTopics: ProfileTopic[] = session.Topics.filter((item) => item.IsSelected) || [];
	const [selectedItems, setSelectedItems] = useState<ProfileTopic[]>(selectedTopics);
	const setPopup = usePopupStore((state) => state.setPopup);
	const { t } = useTranslation();
	const { setAlert } = useAlert();
	const { mutateAsync: updateUserAccount } = useUpdateUserAccount();


	const topics: ProfileTopic[] = session.Topics || [];

	if (!topics || topics.length === 0) {
		return (
			<Stack width={1} height={250} justifyContent={'center'} alignItems={'center'}>
				<Typography variant="h5" fontWeight={700}>
					{t('header.noTopics')}
				</Typography>
			</Stack>
		);
	}

	const handleSelect = (id: number) => {
		let IfExits = selectedItems.find((item) => item.Id === id);
		if (IfExits) {
			const filtered = selectedItems.filter((item) => item.Id !== id);
			setSelectedItems(filtered);
		} else {
			const filtered = topics.filter((item) => item.Id === id);
			setSelectedItems([...selectedItems, ...filtered]);
		}
	};

	const getQueryParams = () => {
		let topicsIds = selectedItems.map((item) => item.Id).join(',');
		return topicsIds
	};


	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		try {
			let createInput: string = `?topics=${getQueryParams()}`;
			const response: IUpdateProfileResponse = await updateUserAccount(createInput);
			if (response.Success === 1) {
				let newTopics = topics.map((item) => {
					let IfExits = selectedItems.find((selected) => selected.Id === item.Id);
					if (IfExits) {
						return { ...item, IsSelected: true }
					}
					else {
						return { ...item, IsSelected: false }
					}
				});
				setSession({ ...session, Topics: newTopics });
				setFilter(`?Topics=${getQueryParams()}`);
				setAlert({
					open: true,
					message: response.Message || t('success.profile_updated_successfully'),
					type: "success"
				});
			}
			else {
				setAlert({
					open: true,
					message: response.Message || t('error.serverError'),
					type: 'error'
				})
			}
		} catch (err: Error | any) {
			setAlert({
				open: true,
				message: err.Message || t('error.serverError'),
				type: 'error'
			})
		}
		setPopup(emptyPopup);
	};

	return (
		<Box component={'form'} onSubmit={handleSubmit}>
			<Stack spacing={3} useFlexGap>
				<Stack maxHeight={'50dvh'} sx={{ overflowY: 'auto' }}>
					{topics.map((topic) => (
						<TopicItemForShowcase topic={topic}
							isSelected={!!selectedItems.find((item) => item.Id === topic.Id)}
							handleSelect={handleSelect} />
					))}
				</Stack>
				<Stack direction={'row'} justifyContent={'flex-end'} spacing={2} useFlexGap>
					<Button onClick={() => setPopup(emptyPopup)}>{t('button.cancel')}</Button>
					<Button variant="contained" type="submit">
						{t('button.apply_changes')}
					</Button>
				</Stack>
			</Stack>
		</Box>
	);
};

export default AddTopicPopupForShowcase;
