const AccountsBLSvg: React.FC = () => {
	return (
		<svg width="95" height="173" viewBox="0 0 95 173" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M70.8424 172.037C62.2052 167.806 54.6703 162.135 49.6603 154.536C24.4484 116.293 54.6708 68.3421 73.2735 49.9424C103.397 20.1481 97.0463 3.16112 78.0956 0.515289C56.2838 -2.53049 17.841 25.8711 0 40.0621V172.037H70.8424Z"
				fill="#4B2AAD"
			/>
		</svg>
	);
};

export default AccountsBLSvg;
