import ActionLoader from 'components/UI/ActionLoader';
import { Pages } from 'enums/pages';
import { Suspense, lazy } from 'react';
import Support from 'pages/support';
import Help from 'components/help';
import AuthForm from 'components/auth/auth-form';

const Showcases = lazy(() => import('pages/showcases'));
const MyProgress = lazy(() => import('pages/my-progress'));
const SurveyAndTests = lazy(() => import('pages/survey-and-tests'));
const History = lazy(() => import('pages/history'));
const Settings = lazy(() => import('pages/settings'));

interface Props {
	slug: string;
}

const DashboardPages: React.FC<Props> = ({ slug }) => {
	const renderContent = (): React.ReactNode => {
		if (slug === Pages.SHOWCASES) return <Showcases />;
		if (slug === Pages.MY_PROGRESS) return <MyProgress />;
		if (slug === Pages.SURVEY_AND_TESTS) return <SurveyAndTests />;
		if (slug === Pages.HISTORY) return <History />;
		if (slug === Pages.SETTINGS) return <Settings />;
		if (slug === Pages.SUPPORT) return <Support />;
		if (slug === Pages.HELP) return <Help />;
		if (slug === Pages.FINISH_REGISTRATION) return <AuthForm slug={slug} />;

		return <>{slug!}</>;
	};

	return <Suspense fallback={<ActionLoader position="fixed" />}>{renderContent()}</Suspense>;
};

export default DashboardPages;
