export type TModalType = '' | 'pages-modal' | 'pages-item-modal' | 'sections-modal' | 'header-dialog' | 'logo-dialog' | 'button-dialog' | 'menu-modal' | 'menu-item-modal';
export type TModalAnchor = 'top' | 'right' | 'bottom' | 'left';

export type TModal = {
	anchor: TModalAnchor;
	type: TModalType;
	title: string;
	content: React.ReactNode;
};

export const emptyModal: TModal = {
	anchor: 'bottom',
	type: '',
	title: '',
	content: null
};
