import { FC } from "react";

import { IFormField } from "types/formFields";
import RHPasswordField from "components/UI/FormFields/RHPasswordField";
import RHCheckbox from "components/UI/FormFields/RHCheckbox";
import RHAutoComplete from "components/UI/FormFields/RHAutoComplete";
import RHFiles from "components/UI/FormFields/RHFiles";
import RHDropzone from "./RHDropzone";
import { IOption } from "types/option";
import { InputTypes } from "enums/input-types";
import RHTextField from "./RHTextField";
import RHPhoneField from "./RHPhoneField";

interface Props extends IFormField {
  errors: any;
  control: any;
  watch: any;
  setValue: any;
  register: any;
  options?: IOption[];
  required?: boolean;
  defaultValue?: any;
}

const FormFields: FC<Props> = (props) => {
  const { type, hidden } = props;

  const renderField = (): React.ReactNode => {
    if (hidden) return null;
    if (type === InputTypes.TEXT || type === InputTypes.HIDDEN || type === InputTypes.NUMBER
      || type === InputTypes.TEXTAREA
    ) {
      return <RHTextField  {...props} />;
    }


    if (type === InputTypes.EMAIL) {
      return <RHTextField {...props} />;
    }
    if (type === InputTypes.PASSWORD) {
      return <RHPasswordField {...props} />;
    }

    if (type === InputTypes.CHECKBOX) {
      return <RHCheckbox {...props} />;
    }

    if (type === InputTypes.MULTI_SELECT) {
      return <RHAutoComplete {...props} multiple={true} defaultValue={props.defaultValue || []} />;
    }

    if (type === InputTypes.SELECT) {
      return <RHAutoComplete {...props} />;
    }
    if (type === InputTypes.FILE) {
      return <RHFiles {...props} />;
    }
    if (type === InputTypes.MULTIPLE_FILES) {
      return <RHDropzone {...props} />;
    }
    if (type === InputTypes.PHONE) {
      return <RHPhoneField {...props} />;
    }



    return null;
  };

  return <>{renderField()}</>;
};

export default FormFields;
