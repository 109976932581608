import { Box } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';

interface Props extends LinkProps {
	children?: React.ReactNode;
}

const ListItemLink: React.FC<Props> = (props) => {
	return (
		<Box width={1} height={1} display={'flex'} alignItems={'center'} color={'inherit'} sx={{ textDecoration: 'none', outline: 0 }} component={Link} {...props}>
			{props.children!}
		</Box>
	);
};

export default ListItemLink;
