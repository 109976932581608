import { Add, Check } from '@mui/icons-material';
import { Box, Button, Grid } from '@mui/material';
import { useEffect } from 'react';
import { Control } from 'react-hook-form';
import { ITopic } from 'types/topic';

interface Props {
	control: Control;
	errors: any;
	watch: any;
	setValue: any;
	register: any;
	data?: any;
}

const InterestsFields: React.FC<Props> = ({ register, watch, setValue, data }) => {
	const value = watch('topics');

	useEffect(() => {
		register('topics');
		// eslint-disable-next-line
	}, []);

	const handleSelect = (Id: number) => {
		const newSelected: Set<number> = new Set<number>(watch('topics'));
		newSelected.has(Id) ? newSelected.delete(Id) : newSelected.add(Id);
		setValue('topics', newSelected);
	};

	return (
		<Box maxHeight={320} sx={{ overflowY: 'auto' }} px={3}>
			<Grid container justifyContent={'center'}
				p={1}  >
				{data.Data.map((item: ITopic) => (
					<Button
						key={item.Id}
						variant="contained"
						color={value.has(item.Id) ? 'primary' : 'inherit'}
						size="small"
						endIcon={value.has(item.Id) ? <Check fontSize="small" /> : <Add fontSize="small" />}
						sx={{
							fontSize: '1.0785rem',
							borderRadius: '32px',
							mx: 1,
							my: '4px',
							py: 0,
							bgcolor: value.has(item.Id) ? 'primary.main' : '#F2F2F2',
							'&.MuiButton-contained': { color: value.has(item.Id) ? 'common.white' : '#242424' }
						}}
						onClick={() => handleSelect(item.Id)}>
						{item.Name}
					</Button>
				))}
			</Grid>
		</Box>
	);
};

export default InterestsFields;
