import { useMutation } from '@tanstack/react-query';
import { Endpoints } from 'enums/endpoints';
import http from 'utils/http';

export const NotificationMarkAsRead = () => {
	return useMutation<any, Error, any>(async (input) => {
		const { data } = await http.post(`${Endpoints.NOTIFICATION_MARK_READ}?Id=${input.Id}`);
		return data;
	});
};
export const NotificationMarkMultipleAsRead = () => {
	return useMutation<any, Error, any>(async (input) => {
		const { data } = await http.post(`${Endpoints.NOTIFICATION_MARK_MULTIPLE_READ}?notificationIds=${input}`);
		return data;
	});
};
export const DeleteNotification = () => {
	return useMutation<any, Error, any>(async (input) => {
		const { data } = await http.post(`${Endpoints.DELETE_NOTIFICATION}?Id=${input.Id}`);
		return data;
	});
};
