import { Control, Controller } from 'react-hook-form';
import { Box, Typography, useTheme } from '@mui/material';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { IFormField } from 'types/formFields';

interface Props extends IFormField {
	errors: any;
	control: Control;
	onChange?: any;

}

const RHPhoneField: React.FC<Props> = ({ label, name, placeholder, disabled, autoFocus, control, errors, onChange }) => {
	const theme = useTheme();

	return (
		<>
			{label && (
				<Typography sx={{ display: 'block' }} variant="h6" component="label" htmlFor={`${name}`}>
					{label}
				</Typography>
			)}
			<Box
				sx={{
					position: 'relative',
					minHeight: 64,
					'& .PhoneInput': {
						pl: 2,
						border: '1px solid',
						borderColor: errors[name] ? theme.palette.error.main : theme.palette.grey[400],
						borderRadius: '24px'
					},
					input: {
						flex: '1 1',
						minWidth: '0',
						font: 'inherit',
						letterSpacing: 'inherit',
						color: 'currentColor',
						border: '0',
						boxSizing: 'content-box',
						background: 'none',
						height: '1.4375em',
						margin: '0',
						webkitTapHighlightColor: 'transparent',
						display: 'block',
						width: '100%',
						webkitAnimationName: 'mui-auto-fill-cancel',
						animationName: 'mui-auto-fill-cancel',
						webkitAnimationDuration: '10ms',
						animationDuration: '10ms',
						padding: '8.5px 14px'
					}
				}}>
				<Controller
					control={control}
					name={name}
					render={({ field }) => (
						<PhoneInput
							size="small"
							name={field.name}
							value={field.value}
							onChange={(e) => {
								onChange && onChange(e!);
								field.onChange(field.value);
							}}
							// handle select country
							// countrySelectComponent={CountrySelect}
							onBlur={field.onBlur}
							disabled={disabled}
							autoFocus={autoFocus}
							placeholder={placeholder}
							error={!!errors[name]}
						/>
					)}
				/>
			</Box>
		</>
	);
};

export default RHPhoneField;
