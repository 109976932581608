import { useMutation } from '@tanstack/react-query';
import { Endpoints } from 'enums/endpoints';
import { IUpdateProfileResponse } from 'types/auth-form';
import http from 'utils/http';

export const useUpdateUserAccount = () => {
	return useMutation<IUpdateProfileResponse, Error, string>(async (input) => {
		const { data } = await http.post(Endpoints.USER_ACCOUNT_UPDATE + input);

		return data.Data;
	});
};
export const useUpdateUserResponseLanguage = () => {
	return useMutation<any, Error, string>(async (input) => {
		const { data } = await http.post(Endpoints.UPDATE_USER_REFERENCE_SENTENCE_SET + input);

		return data;
	});
};

