import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Backdrop, Badge, Box, Container, Drawer, Grid, IconButton, Stack, Toolbar } from '@mui/material';
import { FC, memo, useState } from 'react';


import NotificationSVG from 'assets/svgs/notification';
import NavMenu from '../nav-menu';
import LanguageSwitcher from '../language-switcher/language-switcher';
import NotificationsMenu from './partials/notifications-menu';
import ProfileMenu from './partials/profile-menu';
import useResponsive from 'hooks/responsive';
import { IMediaQuery } from 'types/media-query';
import ProfileAvatar from 'components/profile-avatar';
import ContentLoader from 'components/UI/ContentLoader';
import RequestError from 'components/UI/RequestError';
import { useNotificationQuery } from 'framework/notifications/query/listing';
import { INotification } from 'types/notification';
import { Link } from 'react-router-dom';
import useAuthStore from 'store/auth';
import { Routes } from 'enums/routes';
import { Pages } from 'enums/pages';
import { Profile } from 'types/profile';
import { LogoMobileSVG, LogoSVG } from 'assets/svgs/logo';
interface IState {
	open: boolean;
	anchorEl: null | { key: string; element: EventTarget & HTMLElement };
}

const INITIAL_STATE: IState = { open: false, anchorEl: null };

interface Props {
	activePage: string;
}

const Dashboard: FC<Props> = ({ activePage }) => {
	const [state, setState] = useState(INITIAL_STATE);
	const { open, anchorEl } = state;
	const isMenuOpen = Boolean(anchorEl);
	const params: IMediaQuery = { query: 'up', key: 'md' };
	const mdUp = useResponsive(params);
	const settings = useAuthStore((state) => state.settings);
	const session: Profile = useAuthStore((state) => state.session);

	const { isLoading, error, data: notificationData } = useNotificationQuery({});

	if (isLoading) return <ContentLoader />;
	if (error) return <RequestError message={error.message} />;

	const notifications: INotification[] = notificationData.Data || [] as INotification[];
	const viewedNotifications: INotification[] = notifications.filter((notification: INotification) => !notification.IsRead)
		|| [] as INotification[];


	const handleMenuOpen = (key: string, event: React.MouseEvent<HTMLElement>) => {
		setState({ ...state, anchorEl: { key, element: event.currentTarget } });
	};

	const handleMenuClose = () => {
		setState({ ...state, anchorEl: null });
	};

	const toggleDrawer = () => setState({ ...state, open: !state.open });

	const renderMenus = () => {
		if (!anchorEl) return null;
		if (anchorEl.key === 'notifications')
			return (
				<NotificationsMenu element={anchorEl.element} open={isMenuOpen} handleClose={handleMenuClose} notifications={notifications} />
			);
		if (anchorEl.key === 'profile') return <ProfileMenu element={anchorEl.element} open={isMenuOpen} handleClose={handleMenuClose} />;
	};


	return (
		<>
			<AppBar
				position="absolute"
				elevation={0}
				sx={{
					bgcolor: 'common.white',
					zIndex: (theme) => theme.zIndex.drawer + 1,
					borderBottom: '1px solid',
					borderColor: 'divider',
					// py: 1
				}}>
				<Container maxWidth="xl">
					<Grid container alignItems={'center'} justifyContent={'space-between'}>
						{open && <Drawer variant="persistent" open={open} sx={{ '.MuiDrawer-paper': { width: 240 } }}>
							<Stack>
								<Toolbar sx={{ mb: 3 }} >
									<Link to={Routes._DASHBOARD + settings.DefaultLandingScreenURL || Pages.SHOWCASES} >

										<Stack direction={'column'} alignItems={'center'} spacing={2} useFlexGap>
											<LogoMobileSVG />
											{/* {session.UseOrganizationLogo === 1 && session.OrganizationLogoPath && <Box sx={{
												width: 120,
												height: 86,
											}}>
												<Box component={'img'} src={session.OrganizationLogoPath} alt="logo" sx={{
													width: '100%',
													height: '100%',
													objectFit: 'contain',
												}} />
											</Box>} */}

										</Stack>
									</Link>
								</Toolbar>
							</Stack>
							<Stack alignItems={'center'} spacing={2} useFlexGap>
								<LanguageSwitcher />
							</Stack>
							<Stack direction={'column'} alignItems={'center'} spacing={2} useFlexGap py={1}>
								<NavMenu activePage={activePage} />
							</Stack>

						</Drawer>}
						{!mdUp && <Grid container alignItems={'center'} justifyContent={'space-between'} sx={{
							direction: 'ltr !important'
						}}>

							<IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggleDrawer} sx={{ display: { md: 'none' } }}>
								<MenuIcon />
							</IconButton>

							{!open && <Link to={Routes._DASHBOARD + settings.DefaultLandingScreenURL || Pages.SHOWCASES} >
								<Stack direction={'row'} alignItems={'center'} useFlexGap>
									<LogoMobileSVG />
									{session.UseOrganizationLogo === 1 && session.OrganizationLogoPath && <Box sx={{
										width: 120,
										height: 60,
									}}>
										<Box component={'img'} src={session.OrganizationLogoPath} alt="logo" sx={{
											width: '100%',
											height: '100%',
											objectFit: 'contain',
										}} />
									</Box>}

								</Stack>
							</Link>}
							<IconButton
								color="inherit"
								aria-label="notification"
								aria-haspopup="true"
								onClick={(e) => handleMenuOpen('notifications', e)}
								sx={{
									width: { xs: 40, md: 48 },
									height: { xs: 40, md: 48 },
									svg: { transform: 'scale(1.2)' },
									mt: 1
								}}>
								<Badge badgeContent={viewedNotifications.length} color="error">
									<NotificationSVG />
								</Badge>
							</IconButton>
						</Grid>}


						{mdUp && <>

							<Link to={Routes._DASHBOARD + settings.DefaultLandingScreenURL || Pages.SHOWCASES} >
								<Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} spacing={2} useFlexGap
								>
									<LogoSVG />
									{session.UseOrganizationLogo === 1 && session.OrganizationLogoPath && <Box sx={{
										width: 215,
										height: 86,
									}}>
										<Box component={'img'} src={session.OrganizationLogoPath} alt="logo" sx={{
											width: '100%',
											height: '100%',
											objectFit: 'contain',
										}} />
									</Box>}

								</Stack>
							</Link>
							<Stack direction={'row'} alignItems={'center'} spacing={2} useFlexGap>
								<LanguageSwitcher />
								<IconButton
									color="inherit"
									aria-label="notification"
									aria-haspopup="true"
									onClick={(e) => handleMenuOpen('notifications', e)}
									sx={{
										width: { xs: 40, md: 48 },
										height: { xs: 40, md: 48 },
										svg: { transform: 'scale(1.2)' }
									}}>
									<Badge badgeContent={viewedNotifications.length} color="error">
										<NotificationSVG />
									</Badge>
								</IconButton>
								<ProfileAvatar onClick={(e) => handleMenuOpen('profile', e)} />
							</Stack> </>}
					</Grid>
				</Container>
			</AppBar>
			{renderMenus()}
			<Backdrop
				sx={{
					zIndex: (theme) => theme.zIndex.drawer - 1,
					alignItems: 'flex-start',
					backgroundColor: 'rgba(225,225,225,0.5)'
				}}
				open={open}
				onClick={toggleDrawer}
			/>

		</>
	);
};

export default memo(Dashboard);
