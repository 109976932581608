import { useMutation } from '@tanstack/react-query';
import { Endpoints } from 'enums/endpoints';
import { AuthCreateInput, IRegisterResponse } from 'types/auth-form';
import http from 'utils/http';

export const useRegister = () => {
	return useMutation<IRegisterResponse, Error, AuthCreateInput>(async (input) => {
		const { data } = await http.post(Endpoints.AUTH_REGISTER, input);

		return data;
	});
};
