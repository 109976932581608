import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type State = {
	IsNextShowcaseUpdatedLang: boolean;
	filter: string;
};

type Actions = {
	updateNextShowcaseLang: (payload: boolean) => void;
	setFilter: (payload: string) => void;
};

const useShowcaseStore = create(
	persist<State & Actions>(
		(set) => ({
			IsNextShowcaseUpdatedLang: false,
			filter: '',
			updateNextShowcaseLang: (payload: boolean) => set((state) => ({ ...state, IsNextShowcaseUpdatedLang: payload })),
			setFilter: (payload: string) => set((state) => ({ ...state, filter: payload })),
		}),
		{ name: 'showcase' }
	)
);

export default useShowcaseStore;
