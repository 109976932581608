import { Controller } from "react-hook-form";
import { Grid, TextField, Typography } from "@mui/material";
import { IFormField } from "types/formFields";

interface Props extends IFormField {
  control: any;
  errors: any;
  hidden?: boolean;
  setValue?: any;
  onChange?: any;
}

const RHTextField: React.FC<Props> = ({
  label,
  name,
  type,
  placeholder,
  disabled,
  autoFocus,
  control,
  errors,
  required,
  defaultValue,
  onChange,
  setValue,
  hidden,
  horizontalLabel,
  errorMessage,
  customItem,
  id,
}) => {
  return (

    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <Grid container alignItems={'center'} hidden={hidden} id={id}>
          <Grid item md={12} lg={horizontalLabel ? 12 : 3} sm={12} xs={12}>
            {label && <Typography variant="body2" fontSize={15} fontWeight={600} textAlign={'start'} minWidth={'16ch'}>
              {label} {errorMessage && <span style={{ color: 'red', fontSize: '20px', }}>*</span>}
            </Typography>}
          </Grid>
          <Grid item md={12} lg={label ? horizontalLabel ? 12 : 9 : 12} sm={12} xs={12}>
            <TextField
              fullWidth
              size="small"
              {...field}
              required={required}
              type={type}
              disabled={disabled}
              autoFocus={autoFocus}
              placeholder={placeholder}
              error={!!errors[name]}
              onChange={(e) => {
                onChange && onChange(e.target.value);
                setValue && setValue(name, e.target.value);
              }}
              helperText={errors[name] && `${errors[name].message}`}
              defaultValue={defaultValue}
              sx={{
                borderRadius: 1,
                "& .MuiOutlinedInput-root": {
                  bgcolor: 'common.white',
                },
              }}
              multiline={type === "textarea"}
              rows={type === "textarea" ? 5 : undefined}
            />
            {customItem && customItem}
          </Grid>
        </Grid>
      )}
    />
  );
};

export default RHTextField;
