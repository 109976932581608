interface Props {
	active?: boolean;
}

const HistorySVG: React.FC<Props> = ({ active }) => {
	return (
		<svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M22 3C22 2.73478 21.8946 2.48043 21.7071 2.29289C21.5196 2.10536 21.2652 2 21 2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V17C2 17.2652 2.10536 17.5196 2.29289 17.7071C2.48043 17.8946 2.73478 18 3 18H8.5L11.2 21.6C11.2931 21.7242 11.4139 21.825 11.5528 21.8944C11.6916 21.9639 11.8448 22 12 22C12.1552 22 12.3084 21.9639 12.4472 21.8944C12.5861 21.825 12.7069 21.7242 12.8 21.6L15.5 18H21C21.2652 18 21.5196 17.8946 21.7071 17.7071C21.8946 17.5196 22 17.2652 22 17V3ZM20 16H15C14.8448 16 14.6916 16.0361 14.5528 16.1056C14.4139 16.175 14.2931 16.2758 14.2 16.4L12 19.333L9.8 16.4C9.70685 16.2758 9.58607 16.175 9.44721 16.1056C9.30836 16.0361 9.15525 16 9 16H4V4H20V16ZM7 8C7 7.73478 7.10536 7.48043 7.29289 7.29289C7.48043 7.10536 7.73478 7 8 7H16C16.2652 7 16.5196 7.10536 16.7071 7.29289C16.8946 7.48043 17 7.73478 17 8C17 8.26522 16.8946 8.51957 16.7071 8.70711C16.5196 8.89464 16.2652 9 16 9H8C7.73478 9 7.48043 8.89464 7.29289 8.70711C7.10536 8.51957 7 8.26522 7 8ZM7 13C7 12.7348 7.10536 12.4804 7.29289 12.2929C7.48043 12.1054 7.73478 12 8 12H12C12.2652 12 12.5196 12.1054 12.7071 12.2929C12.8946 12.4804 13 12.7348 13 13C13 13.2652 12.8946 13.5196 12.7071 13.7071C12.5196 13.8946 12.2652 14 12 14H8C7.73478 14 7.48043 13.8946 7.29289 13.7071C7.10536 13.5196 7 13.2652 7 13Z"
				fill={active ? '#000000' : '#62656A'}
			/>
		</svg>
	);
};

export default HistorySVG;
