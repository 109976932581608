import { useMutation } from '@tanstack/react-query';
import { Endpoints } from 'enums/endpoints';
import { ILoginResponse, AuthCreateInput } from 'types/auth-form';
import http from 'utils/http';

export const useLogin = () => {
	return useMutation<ILoginResponse, Error, AuthCreateInput>(async (input) => {
		const { data } = await http.post(Endpoints.AUTH_LOGIN, input);

		return data;
	});
};
