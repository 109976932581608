import { Email, Help } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import MuiOutlineButton from 'components/UI/MuiOutlineButton';
import { Pages } from 'enums/pages';
import { Routes } from 'enums/routes';
import useApp from 'hooks/app';
import { useTranslation } from 'react-i18next';

const HelpSupport: React.FC = () => {
	const { t } = useTranslation();
	const { push } = useApp();
	const handleOpenSupport = async () => {
		push(`${Routes.DASHBOARD + Pages.SUPPORT}`);
	};
	const handleOpenHelp = async () => {
		push(`${Routes.DASHBOARD + Pages.HELP}`);
	};

	return (
		<Grid container justifyContent={'flex-end'} py={2}>
			<MuiOutlineButton sx={{
				color: '#ADA7A7', textTransform: 'Capitalize', "&:hover": {
					border: 'none'
				}, border: 'none'
			}} size='large'
				onClick={handleOpenSupport}
			>
				<Grid container alignItems={'center'} spacing={1}>
					<Email />
					<Typography mx={1} variant={'body2'}>{t('button.support')}</Typography>
				</Grid>

			</MuiOutlineButton>
			<MuiOutlineButton sx={{
				color: '#ADA7A7', textTransform: 'Capitalize', border: 'none',
				"&:hover": {
					border: 'none'
				}
			}} size='large' onClick={handleOpenHelp}>
				<Grid container alignItems={'center'} spacing={1}>
					<Help />
					<Typography mx={1} variant={'body2'}>{t('button.help')}</Typography>
				</Grid>

			</MuiOutlineButton>
		</Grid>
	);
};

export default HelpSupport;
