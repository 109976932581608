import { Control, Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import { IFormField } from 'types/formFields';

interface Props extends IFormField {
  errors: any;
  control: Control;
}

const RHCheckbox: React.FC<Props> = ({ label, name, disabled, control, errors }) => {
  return (
    <>
      <FormControlLabel
        control={
          <Controller
            control={control}
            name={name}
            render={({ field }) => <Checkbox checked={field.value} value={field.value} name={field.name} onChange={field.onChange} onBlur={field.onBlur} ref={field.ref} disabled={disabled} />}
          />
        }
        label={label}
      />
      {errors[name] && (
        <Typography variant="body2" color={'error'}>
          {errors[name].message}
        </Typography>
      )}
    </>
  );
};

export default RHCheckbox;
