import { Box, Container, Grid } from '@mui/material';
import { Routes as ReactRoutes, Route, useLocation, useParams } from 'react-router-dom';

import { Pages } from 'enums/pages';
import { Routes } from 'enums/routes';
import useResponsive from 'hooks/responsive';
import { IMediaQuery } from 'types/media-query';
import HelpSupport from '../help-support';
import Modal from '../modal';
import NavMenu from '../nav-menu';
import Popup from '../popup';
import SideMenu from '../side-menu';
import DashboardPages from './dashboard-pages';
import { lazy } from 'react';
import MuiSnackbar from 'components/UI/MuiSnackbar';
import { useAlert } from 'context/alertContext';

const ShowcaseDetails = lazy(() => import('pages/showcases/partials/showcase-details'));
const HistoryDetails = lazy(() => import('pages/history/partials/histoy-details'));
const TestDetails = lazy(() => import('pages/survey-and-tests/partials/test-details'));

const Dashboard: React.FC = () => {
	let { slug } = useParams<{ slug: string }>();
	const { pathname } = useLocation();
	const params: IMediaQuery = { query: 'up', key: 'md' };
	const mdUp = useResponsive(params);
	const { alert } = useAlert();

	const getIdPageElement = () => {
		if (slug === Pages.SHOWCASES) return <ShowcaseDetails />;
		if (slug === Pages.HISTORY) return <HistoryDetails />;
		if (slug === Pages.SURVEY_AND_TESTS) return <TestDetails />;
		return undefined;
	};

	return (
		<>
			<SideMenu activePage={slug!} />
			<Box component="main" sx={{ width: '100vw', height: '100vh', overflowY: 'hidden' }}>
				<Container maxWidth="xl">
					<Box height={96} />
					<Grid container height={'calc(100vh - 96px)'} overflow={'hidden'}>
						<Grid item xs={3} sx={{ height: '100%', display: mdUp ? 'block' : 'none' }}>
							<Box height={1} py={3}>
								<NavMenu activePage={slug!} />
							</Box>
						</Grid>
						<Grid item xs={mdUp ? 9 : 12} height={1} display={'flex'} flexDirection={'column'} overflow={'hidden'}>
							<HelpSupport />
							<ReactRoutes>
								<Route path={Routes.ROOT} element={<DashboardPages key={pathname} slug={slug!} />} />
								<Route path={':id'} element={getIdPageElement()} />
							</ReactRoutes>
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Modal />
			<Popup />
			<MuiSnackbar alert={alert!} />

		</>
	);
};

export default Dashboard;
