import React, { createContext, useContext, useState } from 'react';

export interface TestFilters {
	userTestStatusIds: string[];
	competencyIds: string[];
	languageId: string;
	searchText: string;
}

export interface TestFiltersContextData {
	filters: TestFilters;
	queryParams: string;
	setFilters: React.Dispatch<React.SetStateAction<TestFilters>>;
	setQueryParams: React.Dispatch<React.SetStateAction<string>>;
}

export const TestFiltersContext = createContext<TestFiltersContextData>({
	filters: { userTestStatusIds: [], competencyIds: [], languageId: '', searchText: '' },
	queryParams: '',
	setFilters: () => { },
	setQueryParams: () => { }
});

export function useTestFiltersContext() {
	return useContext(TestFiltersContext);
}

type TProps = {
	children: React.ReactNode;
};

export const TestFiltersProvider = ({ children }: TProps) => {
	const [filters, setFilters] = useState<TestFilters>({ userTestStatusIds: [], competencyIds: [], languageId: '', searchText: '' });
	const [queryParams, setQueryParams] = useState<string>('');

	return <TestFiltersContext.Provider value={{ filters, queryParams, setFilters, setQueryParams }}>{children}</TestFiltersContext.Provider>;
};
