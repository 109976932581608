const AccountsTRIconSvg: React.FC = () => {
	return (
		<svg width="356" height="167" viewBox="0 0 356 167" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M246.501 33.3702C220.198 39.7074 197.58 50.8453 179.266 66.4737C166.544 77.3323 155.888 90.3271 145.582 102.894C127.132 125.398 108.052 148.668 76.5624 160.059C52.5092 168.76 27.6958 165.422 13.3469 151.554C1.33994 139.949 -1.76542 122.721 4.60367 103.044C21.4428 51.0191 24.7593 19.3179 22.4797 0H21.0883C21.6598 4.81845 21.8536 10.1856 21.6562 16.0993C20.9209 38.164 14.7465 67.2749 3.3051 102.624C-3.23325 122.823 -0.00365605 140.549 12.3983 152.536C21.6948 161.52 35.2374 166.21 50.1369 166.21C58.8296 166.21 67.9842 164.613 77.0269 161.342C108.869 149.823 128.07 126.405 146.638 103.759C156.907 91.2384 167.522 78.2919 180.153 67.512C221.258 32.4283 276.7 27.0448 315.975 28.7082C331.627 29.3707 346.019 31.1739 357.985 33.1945V31.8109C346.023 29.7984 331.668 28.008 316.066 27.3459C290.932 26.2781 267.522 28.3056 246.501 33.3702Z"
				fill="#4B2AAD"
			/>
		</svg>
	);
};

export default AccountsTRIconSvg;
