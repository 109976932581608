import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import { Languages } from "enums/languages";

interface Props {
  content: string;
  sx?: SxProps
}

const HtmlContent: React.FC<Props> = ({ content, sx }) => {
  let language = localStorage.getItem("i18nextLng") || "en";
  return (
    <Box
      sx={{
        img: { maxWidth: "100%", height: "auto" },
        p: {
          fontFamily: language === Languages.ARABIC ? "Tajawal_Regular" : ["roboto", "sans-serif"].join(",")
        },
        ...sx
      }}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};

export default HtmlContent;
