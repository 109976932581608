import { Close } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import usePopupStore from 'store/popup';
import { TPopup, emptyPopup } from 'types/popup';

type Props = {};

const Popup = (props: Props) => {
	const popup: TPopup = usePopupStore((state) => state.popup);
	const setPopup = usePopupStore((state) => state.setPopup);

	return (
		<Dialog
			open={popup.open}
			onClose={() => setPopup(emptyPopup)}
			maxWidth={'md'}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
			sx={{
				p: 4,
				'.MuiDialog-paper': {
					borderRadius: '16px',
					width: { xs: 1, md: 0.35 },
					maxHeight: 1,
					overflow: 'hidden'
				},
				'&::-webkit-scrollbar': {
					display: 'none'
				}
			}} >
			<DialogTitle id="alert-dialog-title">
				<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
					<Typography variant="h6" fontWeight={700} color={'primary.main'}>
						{popup.title}
					</Typography>
					<IconButton onClick={() => setPopup(emptyPopup)}>
						<Close />
					</IconButton>
				</Stack>
			</DialogTitle>
			<DialogContent>
				{popup.content}
			</DialogContent>
		</Dialog>
		// <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, p: 4 }} open={popup.open}>
		// 	<Paper sx={{ width: { xs: 1, md: 0.35 }, maxHeight: 1, borderRadius: '16px' }}>
		// 		<Stack height={1} p={3} spacing={3} useFlexGap>
		// 			<Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
		// 				<Typography variant="h6" fontWeight={700} color={'primary.main'}>
		// 					{popup.title}
		// 				</Typography>
		// 				<IconButton onClick={() => setPopup(emptyPopup)}>
		// 					<Close />
		// 				</IconButton>
		// 			</Stack>
		// 			{popup.content}
		// 		</Stack>
		// 	</Paper>
		// </Backdrop>
	);
};

export default Popup;
