import { useParams } from 'react-router-dom';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';

import { Pages } from 'enums/pages';
import useResponsive from 'hooks/responsive';
import { IMediaQuery } from 'types/media-query';

import AccountsBLSvg from 'assets/svgs/accounts-bl-image';
import AccountsBLIconSvg from 'assets/svgs/accounts-bl-icon';
import AccountsTRImageSvg from 'assets/svgs/accounts-tr-image';
import AccountsTRIconSvg from 'assets/svgs/accounts-tr-icon';
import AuthForm from './auth-form';
import AccountsMainBg from 'assets/svgs/accounts-main-bg';
import MuiSnackbar from 'components/UI/MuiSnackbar';
import { useAlert } from 'context/alertContext';

interface Props { }

const Auth: React.FC<Props> = () => {
	let { slug } = useParams<{ slug: string }>();
	let { activateToken } = useParams<{ activateToken: string }>();
	let { resetToken } = useParams<{ resetToken: string }>();
	const params: IMediaQuery = { query: 'up', key: 'md' };
	const mdUp = useResponsive(params);
	const { alert } = useAlert();


	if (!slug && activateToken) slug = Pages.ACTIVATE_ACCOUNT;
	if (!slug && resetToken) slug = Pages.RESET_PASSWORD;
	if (!slug) slug = Pages.LOGIN;

	const renderTitleText = (): string => {
		if (slug === Pages.LOGIN) return 'login';
		if (slug === Pages.REGISTER) return 'sign up';

		return '';
	};

	return (
		<>
			{(slug === Pages.LOGIN || slug === Pages.REGISTER || slug === Pages.CONFIRM_OTP) && (
				<Grid container>
					<Grid item xs={12} md={6}>
						{mdUp && (
							<Box
								sx={{
									position: 'relative',
									height: '100vh',
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									flexDirection: 'column',
									minHeight: '100%',
									overflow: 'hidden',
									backgroundColor: 'primary.main'
								}}>
								{/* Top right background image */}
								<Box
									sx={{
										position: 'absolute',
										right: 0,
										top: 0,
										svg: { display: 'block' }
									}}>
									<AccountsTRImageSvg />
								</Box>
								<Box
									sx={{
										position: 'absolute',
										right: 0,
										top: 0,
										svg: { display: 'block' }
									}}>
									<AccountsTRIconSvg />
								</Box>
								{/* background image */}
								<Box
									sx={{
										position: 'absolute',
										width: '75%',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%,-50%)'
									}}>
									<AccountsMainBg />
								</Box>
								{/* Left bottom background image */}
								<Box
									sx={{
										position: 'absolute',
										bottom: -3,
										left: 0,
										svg: { display: 'block' }
									}}>
									<AccountsBLSvg />
								</Box>
								<Box
									sx={{
										position: 'absolute',
										bottom: -3,
										left: 0,
										svg: { display: 'block' }
									}}>
									<AccountsBLIconSvg />
								</Box>
								<Stack height={1} width={1} justifyContent={'flex-end'} pb={4}>
									<Typography textTransform={'capitalize'} component={'p'} variant="h5" textAlign={'center'} color={'common.white'}>
										{renderTitleText()}
									</Typography>
									<Typography component={'p'} variant="h5" textAlign={'center'} color={'common.white'} paragraph>
										and come on in
									</Typography>
									<Typography fontWeight={300} textAlign={'center'} color={'common.white'}>
										&copy; Scofolio
									</Typography>
								</Stack>
							</Box>
						)}
					</Grid>
					<Grid item xs={12} md={6}>
						<Stack width={1} height={1} justifyContent={'center'} spacing={2} px={{ xs: 3, lg: 6 }} sx={{ overflowY: 'auto' }}>
							<AuthForm slug={slug} />
						</Stack>
					</Grid>
				</Grid>
			)}
			{slug !== Pages.LOGIN && slug !== Pages.REGISTER && slug !== Pages.CONFIRM_OTP && (
				<Container maxWidth="sm">
					<Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'}>
						<AuthForm slug={slug!} activateToken={activateToken} resetToken={resetToken} />
					</Grid>
				</Container>
			)}
			<MuiSnackbar alert={alert!} />

		</>
	);

};

export default Auth;
