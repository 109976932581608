import { useMutation } from '@tanstack/react-query';
import { Endpoints } from 'enums/endpoints';
import http from 'utils/http';

export const useVerifyLoginOTP = () => {
	return useMutation<any, Error, any>(async (input: any) => {
		const { data } = await http.post(Endpoints.VERIFY_LOGIN_OTP + '?Token=' + input.Token + '&userId=' + input.userId)

		return data;
	});
};
