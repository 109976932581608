const NotificationSVG: React.FC = () => {
	return (
		<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M13.8452 7.67822V11.3728" stroke="#ADA7A7" strokeWidth="1.66424" strokeMiterlimit="10" strokeLinecap="round" />
			<path
				d="M13.8674 2.7522C9.78445 2.7522 6.47816 6.05849 6.47816 10.1414V12.4714C6.47816 13.2258 6.1675 14.3575 5.77918 15.001L4.37012 17.3531C3.50471 18.8066 4.10384 20.4264 5.70151 20.959C11.0049 22.7231 16.741 22.7231 22.0444 20.959C23.5422 20.4597 24.1857 18.7067 23.3758 17.3531L21.9667 15.001C21.5784 14.3575 21.2677 13.2147 21.2677 12.4714V10.1414C21.2566 6.08068 17.9281 2.7522 13.8674 2.7522Z"
				stroke="#ADA7A7"
				strokeWidth="1.66424"
				strokeMiterlimit="10"
				strokeLinecap="round"
			/>
			<path
				d="M17.5396 21.4141C17.5396 23.4444 15.8754 25.1087 13.845 25.1087C12.8354 25.1087 11.9034 24.6871 11.2377 24.0214C10.572 23.3557 10.1504 22.4237 10.1504 21.4141"
				stroke="#ADA7A7"
				strokeWidth="1.66424"
				strokeMiterlimit="10"
			/>
		</svg>
	);
};

export default NotificationSVG;
