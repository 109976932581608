import { useMutation } from '@tanstack/react-query';
import { Endpoints } from 'enums/endpoints';
import http from 'utils/http';

export const useSaveUserTopics = () => {
	return useMutation<any, Error, Set<number>>(async (input) => {
		const url = '?userTopics=' + Array.from(input).join(',');
		const { data } = await http.post(Endpoints.USER_SAVE_TOPICS + url);

		return data;
	});
};
